import { useAppSelector } from '@/redux/hooks';

type TranslationKeys = {
	btnSendReview: string;
	priceFrom: string;
	productDescription: string;
	mandatory: string;
	textObservation: string;
	option: string;
	options: string;
	optionChooseOf: string;
	optionAtLeast: string;
	chooseOne: string;
	chooseUpTo: string;
	continueShopping: string;
	makePayment: string;
	payment: string;
	paymentCart: string;
	order: string;
	cart: string;
	deliveryFee: string;
	freeShipping: string;
	couponApplied: string;
	resultItems: string;
	items: string;
	paymentMethods: string;
	creditCard: string;
	generatePaymentCode: string;
	confirmPayment: string;
	generatingPayment: string;
	generatingPix: string;
	personalInformation: string;
	provideYourCpf: string;
	fullName: string;
	name: string;
	yourEmail: string;
	yourPhone: string;
	savePersonalInformation: string;
	cardNumber: string;
	month: string;
	year: string;
	paymentSuccessfully: string;
	thankPreference: string;
	purchaseCompleted: string;
	viewTickets: string;
	allSet: string;
	myItems: string;
	loadingItems: string;
	qrCodeProduct: string;
	anyProductsYet: string;
	password: string;
	orderPassword: string;
	orderSlip: string;
	orderStatus: string;
	table: string;
	lastPass: string;
	nameOrder: string;
	orderNav: string;
	contact: string;
	search: string;
	noProductCart: string;
	alsoOrder: string;
	noProductOrder: string;
	businessHours: string;
	address: string;
	phone: string;
	review: string;
	comment: string;
	commentOrsuggestion: string;
	btnClose: string;
	errorSbumittng: string;
	searchProduct: string;
	shearchingProduct: string;
	aplly: string;
	openOrders: string;
	consultation: string;
	codeGenerated: string;
	copyPix: string;
	copyCode: string;
	openBank: string;
	copyAndPaste: string;
	pasteCode: string;
	timeout: string;
	copyCodeFinish: string;
	codeCopiedSuccessfully: string;
	
};

const textData = {
	PT: {
		btnSendReview: 'Enviar avaliação',
		priceFrom: 'a partir de R$',
		productDescription: 'Descrição',
		mandatory: 'Obrigatório',
		textObservation: 'Alguma observação?',
		option: 'opcão',
		options: 'opções',
		optionChooseOf: 'Escolha de',
		optionAtLeast: 'Escolha no mínimo',
		chooseOne: 'Escolha 1 opção.',
		chooseUpTo: 'Escolha até',
		continueShopping: 'Continuar Comprando',
		makePayment: 'Fazer o',
		payment: 'pagamento',
		paymentCart: 'Pagamento',
		order: 'pedido',
		cart: 'Carrinho',
		deliveryFee: 'Taxa de entrega',
		freeShipping: 'Frete grátis',
		couponApplied: 'Cupom aplicado',
		resultItems: 'Resultado para',
		items: 'itens',
		paymentMethods: 'Meios de pagamento:',
		creditCard: 'Cartão de crédito',
		generatePaymentCode: 'Gerar código para pagamento',
		confirmPayment: 'Confirmar pagamento',
		generatingPayment: 'Processando pagamento, aguarde por favor...',
		generatingPix: 'Gerando código PIX, aguarde por favor...',
		personalInformation: 'Dados Pessoais:',
		provideYourCpf: 'Informe seu CPF',
		fullName: 'Nome completo',
		name: 'Nome',
		yourEmail: 'Qual seu e-mail?',
		yourPhone: 'Seu telefone',
		savePersonalInformation: 'Salvar dados pessoais para compras futuras.',
		cardNumber: 'Número do cartão',
		month: 'Mês *',
		year: 'Ano *',
		paymentSuccessfully: 'Pagamento efetuado com sucesso!',
		thankPreference: 'Obrigado(a) pela preferência!',
		purchaseCompleted: 'Compra Concluída',
		viewTickets: 'Ver Tickets',
		allSet: 'TUDO CERTO...',
		myItems: 'Meus Itens',
		loadingItems: 'Estamos carregando seus itens...',
		qrCodeProduct: 'Apresente o QRCODE para retirar o produto',
		anyProductsYet: 'Você ainda não adquiriu produtos!',
		password: 'Senha:',
		orderPassword: 'Senha do pedido:',
		orderSlip: 'Comanda:',
		orderStatus: 'Status da Comanda:',
		table: 'Mesa:',
		lastPass: 'Última senha:',
		nameOrder: 'Nome:',
		orderNav: 'Comanda',
		contact: 'Contato',
		search: 'Busca',
		noProductCart: 'Nenhum produto adicionado ao carrinho',
		noProductOrder: 'Nenhum produto na comanda!',
		alsoOrder: 'Peça também',
		businessHours: 'Horário de funcionamento',
		address: 'Endereço',
		phone: 'Telefone',
		review: 'Deixe sua avaliação',
		comment: 'Deixe seu comentário:',
		commentOrsuggestion: 'Deixe aqui seu comentário ou sugestão',
		btnClose: 'Fechar',
		errorSbumittng: 'Erro ao enviar avaliação.',
		searchProduct: 'Buscar pelo produto...',
		shearchingProduct: 'Buscando produtos...',
		aplly: 'Aplicar',
		openOrders: 'Aberto para pedidos',
		consultation: 'Apenas consulta',
		codeGenerated: 'Código gerado com sucesso:',
		copyPix: '1- Copie o código pix abaixo pelo botão',
		copyCode: 'Copiar Código:',
		openBank: ' 2- Abra o aplicativo do banco que tenha o PIX habilitado e utilize a opção',
		copyAndPaste: 'Copia e Cola:',
		pasteCode: '3- Cole o código copiado e faça o pagamento',
		timeout: 'O tempo para você pagar acaba em:',
		copyCodeFinish: 'Copiar código',
		codeCopiedSuccessfully: 'Código copiado com sucesso!',
	},
	EN: {
		btnSendReview: 'Send evaluation',
		priceFrom: 'from R$',
		productDescription: 'Description',
		mandatory: 'mandatory',
		textObservation: 'Any observations?',
		option: 'option',
		options: 'options',
		optionChooseOf: 'Choice of',
		optionAtLeast: 'Choose at least',
		chooseOne: 'Choose 1 option.',
		chooseUpTo: 'Choose up to',
		continueShopping: 'Continue Shopping',
		makePayment: 'Make the',
		payment: 'payment',
		paymentCart: 'Payment',
		order: 'order',
		cart: 'Cart',
		deliveryFee: 'Delivery Fee',
		freeShipping: 'Free Shipping',
		couponApplied: 'Coupon Applied',
		resultItems: 'Result for',
		items: 'items',
		paymentMethods: 'Payment Methods:',
		creditCard: 'Credit Card',
		generatePaymentCode: 'Generate payment code',
		confirmPayment: 'Confirm payment',
		generatingPayment: 'Processing payment, please wait...',
		generatingPix: 'Generating PIX code, please wait...',
		personalInformation: 'Personal Information:',
		provideYourCpf: 'Please provide your CPF',
		fullName: 'Full Name',
		name: 'Name',
		yourEmail: 'What is your email?',
		yourPhone: 'Your phone number',
		savePersonalInformation: 'Save personal information for future purchases.',
		cardNumber: 'Card number',
		month: 'Month *',
		year: 'Year *',
		paymentSuccessfully: 'Payment successfully completed!',
		thankPreference: 'Thank you for your preference!',
		purchaseCompleted: 'Purchase Completed',
		viewTickets: 'View Tickets',
		allSet: 'ALL SET...',
		myItems: 'My Items',
		loadingItems: 'We are loading your items...',
		qrCodeProduct: 'Present the QR code to collect the product.',
		anyProductsYet: 'You have not purchased any products yet!',
		password: 'Password:',
		orderPassword: 'Order password:',
		orderSlip: 'Order slip:',
		orderStatus: 'Order Status:',
		table: 'Table:',
		lastPass: 'Last password:',
		nameOrder: 'Name:',
		orderNav: 'Order slip',
		contact: 'Contact',
		search: 'Search',
		noProductCart: 'No product added to the cart',
		alsoOrder: 'Also order',
		noProductOrder: 'No product in the order slip',
		businessHours: 'Business hours',
		address: 'Address',
		phone: 'Phone',
		review: 'Leave your review',
		comment: 'Leave your comment:',
		commentOrsuggestion: 'Leave your comment or suggestion here',
		btnClose: 'Close',
		errorSbumittng: 'Error submitting review.',
		searchProduct: 'Search for the product...',
		shearchingProduct: 'Searching for products...',
		aplly: 'Apply',
		openOrders: 'Open for orders',
		consultation: 'Consultation only',
		codeGenerated: 'Code generated successfully:',
		copyPix: '1- Copy the PIX code below using the button',
		copyCode: 'Copy Code:',
		openBank: '2- Open the bank app that has PIX enabled and use the option',
		copyAndPaste: 'Copy and Paste:',
		pasteCode: '3- Paste the copied code and make the payment',
		timeout: 'The time to make your payment ends in:',
		copyCodeFinish: 'Copy code',
		codeCopiedSuccessfully: 'Code copied successfully!',

	},
	ES: {
		btnSendReview: 'Enviar evaluación',
		priceFrom: 'a partir de R$',
		productDescription: 'Descripción',
		mandatory: 'obligatorio',
		textObservation: '¿Alguna observación?',
		option: 'opción',
		options: 'opciones',
		optionChooseOf: 'Elección de',
		optionAtLeast: 'Elige al menos',
		chooseOne: 'Elige 1 opción.',
		chooseUpTo: 'Escolha até',
		continueShopping: 'Continuar Comprando',
		makePayment: 'Realizar el',
		payment: 'pago',
		paymentCart: 'Pago',
		order: 'pedido',
		cart: 'Carrito',
		deliveryFee: 'Taxa de entrega',
		freeShipping: 'Envío Gratis',
		couponApplied: 'Cupón Aplicado',
		resultItems: 'Resultado para',
		items: 'artículos',
		paymentMethods: 'Métodos de Pago:',
		creditCard: 'Tarjeta de Crédito',
		generatePaymentCode: 'Generar código de pago',
		confirmPayment: 'Confirmar pago',
		generatingPayment: 'CProcesando el pago, por favor espere...',
		generatingPix: 'Generando código PIX, por favor espere...',
		personalInformation: 'Datos Personales:',
		provideYourCpf: 'Por favor, proporcione su CPF',
		fullName: 'Nombre completo',
		name: 'Nombre',
		yourEmail: '¿Cuál es tu correo electrónico?',
		yourPhone: 'Tu teléfono',
		savePersonalInformation: 'Guardar datos personales para futuras compras.',
		cardNumber: 'Número de tarjeta',
		month: 'Mes *',
		year: 'Año *',
		paymentSuccessfully: '¡Pago efectuado con éxito!',
		thankPreference: '¡Gracias por su preferencia!',
		purchaseCompleted: 'Compra Concluida',
		viewTickets: 'Ver Tickets',
		allSet: 'TODO LISTO...',
		myItems: 'Mis Artículos',
		loadingItems: 'Estamos cargando tus artículos...',
		qrCodeProduct: 'Presente el código QR para retirar el producto.',
		anyProductsYet: '¡Aún no has adquirido productos!',
		password: 'Contraseña:',
		orderPassword: 'Contraseña del pedido:',
		orderSlip: 'Comanda:',
		orderStatus: ' Estado de la Comanda:',
		table: 'Mesa:',
		lastPass: 'Última contraseña:',
		nameOrder: 'Nombre:',
		orderNav: 'Comanda',
		contact: 'Contacto',
		search: 'Búsqueda',
		noProductCart: 'Ningún producto agregado al carrito',
		alsoOrder: 'Pide también',
		noProductOrder: '¡No hay producto en la comanda!',
		businessHours: 'Horario de funcionamiento',
		address: 'Dirección',
		phone: 'Teléfono',
		review: 'Deja tu reseña',
		comment: 'Deja tu comentario:',
		commentOrsuggestion: 'Deja aquí tu comentario o sugerencia',
		btnClose: 'Cerrar',
		errorSbumittng: 'Error al enviar la reseña.',
		searchProduct: 'Buscar el producto...',
		shearchingProduct: 'Buscando productos...',
		aplly: 'Aplicar',
		openOrders: 'Abierto para pedidos',
		consultation: 'Solo consulta',
		codeGenerated: 'Código generado con éxito:',
		copyPix: '1- Copie el código PIX abajo utilizando el botón',
		copyCode: 'Copiar Código:',
		openBank: '2- Abra la aplicación del banco que tenga habilitado el PIX y utilice la opción',
		copyAndPaste: 'Copiar y Pegar:',
		pasteCode: '3- Pega el código copiado y realiza el pago',
		timeout: 'El tiempo para realizar tu pago termina en:',
		copyCodeFinish: 'Copiar código',
		codeCopiedSuccessfully: '¡Código copiado con éxito!',
	},
};

export default function useTranslation() {
	const { language } = useAppSelector(state => state.auth);

	const returnTranslation = (value: keyof TranslationKeys) => {
		const toReturnText = textData[language][value];
		return toReturnText;
	};

	const changeApiLanguage = (value: string, parent?: any) => {
		if (!parent) return '';
		let tempValue = value;

		if (language === 'EN') {
			tempValue = value + '_en';
		} else if (language === 'ES') {
			tempValue = value + '_es';
		}

		const keyTyped = tempValue as keyof typeof parent;
		return parent?.[keyTyped] as string;
	};

	return { returnTranslation, changeApiLanguage };
}
