import React from 'react';

export default function CheckIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.1933 23.8069C34.538 33.8568 24.2414 39.8481 14.1933 37.1932C4.14516 34.5384 -1.84838 24.2431 0.80692 14.1932C3.46126 4.14691 13.7589 -1.84797 23.807 0.806849C33.8551 3.46167 39.8477 13.7606 37.1933 23.8069Z"
        fill="#41A839"
      />
      <path
        d="M17.0415 28.3236C16.4815 28.3852 15.8982 28.2344 15.4236 27.8539L8.83829 22.5746C7.8892 21.8137 7.73676 20.4288 8.4979 19.4822C9.25876 18.5331 10.641 18.3784 11.59 19.1393L16.4604 23.0437L25.6442 11.5881C26.405 10.639 27.7875 10.4868 28.7366 11.2477C29.6857 12.0086 29.8381 13.3935 29.0772 14.3426L18.5185 27.5133C18.1381 27.9878 17.6015 28.262 17.0415 28.3236Z"
        fill="white"
      />
    </svg>
  );
}
