import { createSlice } from '@reduxjs/toolkit';
import { ListBannersResponse } from '@/models/listBanners.response';
import { ListProductsResponse } from '@/models/listProducts.response';
import { ListDepartmentsResponse } from '@/models/listDepartments.response';
import {
  GetBanners,
  GetDepartments,
  GetProducts,
} from '@/services/delivery/menu';

const initialState = {
  listBanners: <ListBannersResponse | null>null,
  listProducts: <ListProductsResponse | null>null,
  listDepartments: <ListDepartmentsResponse | null>null,
  loading: false,
  requestError: <null | string>null,
};

const menuDeliverySlice = createSlice({
  name: 'menuDelivery',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetBanners.pending, (state) => {
        state.requestError = null;
        state.listBanners = null;
        state.loading = true;
      })
      .addCase(GetBanners.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.listBanners = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(GetProducts.pending, (state) => {
        state.requestError = null;
        state.listProducts = null;
        state.loading = true;
      })
      .addCase(GetProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.listProducts = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(GetDepartments.pending, (state) => {
        state.requestError = null;
        state.listDepartments = null;
        state.loading = true;
      })
      .addCase(GetDepartments.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.listDepartments = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      });
  },
});

export const { reset } = menuDeliverySlice.actions;
export default menuDeliverySlice.reducer;
