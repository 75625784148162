import { Modal } from '@mui/material';
import React from 'react';
import QRCode from 'react-qr-code';

type ModalType = {
  open: boolean;
  handleClose(): void;
  value: string;
};

export default function ModalQrCode({ open, handleClose, value }: ModalType) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      disablePortal={true}
      disableEnforceFocus={true}
      id="modalQrCode"
    >
      <div className="modalContent">
        <div className="boxQrCode">
          <QRCode value={value} />
        </div>
      </div>
    </Modal>
  );
}
