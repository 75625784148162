import { MakePaymentResponse } from '@/models/makePayment.response';
import { StatusTransitionResponse } from '@/models/statusTransition.response';
import { GetStatusTransition, PostPayment } from '@/services/payment';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  responsePayment: <MakePaymentResponse | null>null,
  status: <StatusTransitionResponse | null>null,
  loading: false,
  loadingStatus: false,
  requestError: <null | string>null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostPayment.pending, (state) => {
        state.requestError = null;
        state.responsePayment = null;
        state.loading = true;
      })
      .addCase(PostPayment.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.responsePayment = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(GetStatusTransition.pending, (state) => {
        state.requestError = null;
        state.status = null;
        state.loadingStatus = true;
      })
      .addCase(GetStatusTransition.fulfilled, (state, { payload }) => {
        state.loadingStatus = false;
        if (payload) {
          if (payload.retorno) {
            state.status = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      });
  },
});

export const { reset } = paymentSlice.actions;
export default paymentSlice.reducer;
