import CommonMask from '@/helpers/masks';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CartType } from '@/models/cartModel';
import AddIcon from '@/assets/svg/Add';
import SubIcon from '@/assets/svg/Sub';
import { ListProductsResponse } from '@/models/listProducts.response';
import { usePages } from '@/contexts/pages';
import useTranslation from '@/hooks/cardapioUseTranslation';

type OptionalType = {
	option: ListProductsResponse['conteudo'][0]['opcionais'][0];
	changeOptionals(newOptional: CartType['items'][0]['options'], catId: number): void;
	allAccess: boolean;
	scrollToNextItem(indexNext: number): void;
	index: number;
	handleOpenModal: ({ src, title, price }: { src: string; title: string; price: string }) => void;
};

export default function OptionalQtd({ option, changeOptionals, allAccess, index, scrollToNextItem, handleOpenModal }: OptionalType) {
	const [qtd, setQtd] = useState<any>({});
	const [items, setItems] = useState<CartType['items'][0]['options']>([]);
	const [canAdd, setCanAdd] = useState(true);
	const { openProduct } = usePages();
	const { changeApiLanguage } = useTranslation();

	useEffect(() => {
		if (!openProduct) {
			setItems([]);
			setQtd({});
			setCanAdd(true);
		}
	}, [openProduct]);

	useEffect(() => {
		if (openProduct) {
			const defaultQtd: any = {};
			option.registros.forEach(item => {
				defaultQtd[item.id] = 0;
			});
			setQtd(defaultQtd);
		}
	}, [openProduct]);

	function changeQtd(item: ListProductsResponse['conteudo'][0]['opcionais'][0]['registros'][0], itemQtd: number) {
		const newArrItems = [...items];

		if (newArrItems.length < 1 && itemQtd > 0) {
			newArrItems.push({
				id: item.id,
				qtd: itemQtd,
				value: item.preco,
				catId: option.id,
				name: item.descricao,
			});
		} else if (itemQtd === 0) {
			newArrItems.forEach((el, index) => {
				if (el.id === item.id) {
					newArrItems.splice(index, 1);
				}
			});
		} else {
			let include = false;
			items.forEach((el, index) => {
				if (el.id === item.id) {
					include = true;
					newArrItems[index].qtd = itemQtd;
				}
			});

			if (!include) {
				newArrItems.push({
					id: item.id,
					qtd: itemQtd,
					value: item.preco,
					catId: option.id,
					name: item.descricao,
				});
			}
		}

		setItems(newArrItems);
		changeOptionals(newArrItems, option.id);
	}

	useEffect(() => {
		let qtd = 0;

		items.forEach(element => {
			qtd = qtd + element.qtd;
		});

		if (option.qtde_max_selecao > 0 && qtd === option.qtde_max_selecao) {
			scrollToNextItem(++index);
		}

		if (qtd < option.qtde_max_selecao || option.qtde_max_selecao === 0) {
			setCanAdd(true);
		} else {
			setCanAdd(false);
		}
	}, [items]);

	return (
		<ul>
			{option.registros.map(listItem => (
				<li key={listItem.id} className="optionalBodyItem">
					<div>
						<p className="optionalBodyDesc">{changeApiLanguage('descricao', listItem)}</p>
						{listItem.detalhes && <span className="optionalBodyDetail">{changeApiLanguage('detalhes', listItem)}</span>}
						{listItem.preco > 0 && <span className="optionalBodyPrice">+ R$ {CommonMask.currency(listItem.preco.toFixed(2).toString())}</span>}
					</div>
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						{listItem.tipo === 'P' && (
							<div
								onClick={() => {
									handleOpenModal({
										price: `+R$ ${CommonMask.currency(listItem.preco.toFixed(2).toString())}`,
										src: listItem.imagem,
										title: listItem.descricao,
									});
								}}
								className="optionalThumb"
								style={{
									backgroundImage: `url(${listItem.imagem})`,
								}}></div>
						)}
						{allAccess && (
							<div className="optionalBtns">
								<Button
									variant="text"
									className="optionalBtn"
									onClick={() => {
										qtd[listItem.id] > 0 ? setQtd({ ...qtd, [listItem.id]: qtd[listItem.id] - 1 }) : null;

										if (qtd[listItem.id] - 1 >= 0) {
											changeQtd(listItem, qtd[listItem.id] - 1);
										}
									}}>
									<SubIcon />
								</Button>
								<span className="optionalQtdTxt">{qtd[listItem.id]}</span>
								<Button
									variant="text"
									className="optionalBtn"
									onClick={() => {
										if (canAdd) {
											setQtd({ ...qtd, [listItem.id]: qtd[listItem.id] + 1 });
											changeQtd(listItem, qtd[listItem.id] + 1);
										}
									}}>
									<AddIcon />
								</Button>
							</div>
						)}
					</div>
				</li>
			))}
		</ul>
	);
}
