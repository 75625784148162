import { createContext, useEffect, ReactNode, useContext, useState } from 'react';
import UserStorage from '@/storages/user-storage';
import { CartType } from '@/models/cartModel';
import { useMain } from './main';
import { useAppSelector } from '@/redux/hooks';

type CartContextType = {
	cart: CartType;
	Add(item: CartType['items'][0]): void;
	Remove(item: CartType['items'][0]): void;
	clearCart(): void;
	ChangeQtd(item: CartType['items'][0], type: 'SUM' | 'SUB'): void;
};

const CartContext = createContext<CartContextType>({} as CartContextType);

export function CartProvider({ children }: { children: ReactNode }) {
	const { qrcode } = useMain();
	const { orderResponse } = useAppSelector(state => state.order);
	const { status } = useAppSelector(state => state.payment);
	const [cart, setCart] = useState<CartType>({
		items: [],
		tot: 0,
	});

	useEffect(() => {
		if (qrcode) {
			const storageCart = UserStorage.GetCart(qrcode);
			if (storageCart) {
				setCart(storageCart);
			}
		}
	}, [qrcode]);

	useEffect(() => {
		UserStorage.SetCart(cart);
	}, [cart]);

	function clearCart() {
		setCart({
			items: [],
			tot: 0,
		});
	}

	useEffect(() => {
		if ((orderResponse && orderResponse.retorno) || status?.status === '3') {
			clearCart();
		}
	}, [orderResponse, status]);

	function arraysEqual(a: any, b: any) {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length !== b.length) return false;

		for (var i = 0; i < a.length; ++i) {
			if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) return false;
		}
		return true;
	}

	function calcTot(item: CartType['items'][0], type: 'SUM' | 'SUB') {
		let newTot = cart.tot;
		let newTotOptions = 0;

		if (item.options) {
			item.options.forEach(option => {
				if (option.value) {
					newTotOptions += option.value * option.qtd;
				}
			});
		}

		if (type === 'SUM') {
			newTot = newTot + (newTotOptions + item.unityValue) * item.qtd;
		} else {
			newTot = newTot - (newTotOptions + item.unityValue) * item.qtd;
		}

		return newTot;
	}

	function calcQtd(item: CartType['items'][0], type: 'SUM' | 'SUB') {
		let newTot = cart.tot;
		let newTotOptions = 0;

		if (item.options) {
			item.options.forEach(option => {
				if (option.value) {
					newTotOptions += option.value * option.qtd;
				}
			});
		}

		if (cart.items.length === 1) {
			newTot = (newTotOptions + item.unityValue) * item.qtd;
		} else {
			if (type === 'SUM') {
				newTot = newTot + (newTotOptions + item.unityValue);
			} else {
				newTot = newTot - (newTotOptions + item.unityValue);
			}
		}

		return +newTot.toFixed(2);
	}

	function Add(item: CartType['items'][0]) {
		let newItems: CartType['items'] = [];
		let duplicateProduct = false;

		if (cart.items.length < 1) {
			newItems.push(item);
		} else {
			newItems.push(...cart.items);

			newItems.forEach((currentItem, index) => {
				if (currentItem.id === item.id && arraysEqual(currentItem.options, item.options) && currentItem.unityValue === item.unityValue && currentItem.observation === item.observation) {
					duplicateProduct = true;
					if (newItems && currentItem.qtd && item.qtd) {
						newItems[index].qtd = currentItem.qtd + item.qtd;
					}
				}
			});

			if (!duplicateProduct) {
				newItems.push(item);
			}
		}

		setCart({
			items: newItems,
			tot: calcTot(item, 'SUM'),
		});
	}

	function Remove(item: CartType['items'][0]) {
		if (cart.items.length === 1) {
			setCart({
				items: [],
				tot: 0,
			});
		} else {
			let newListItems: CartType['items'] = [...cart.items];

			newListItems.forEach((currentItem, index) => {
				if (currentItem.id === item.id && arraysEqual(currentItem.options, item.options) && currentItem.unityValue === item.unityValue) {
					newListItems.splice(index, 1);
				}
			});

			setCart({
				items: newListItems,
				tot: calcTot(item, 'SUB'),
			});
		}
	}

	function ChangeQtd(item: CartType['items'][0], type: 'SUM' | 'SUB') {
		let newListItems: CartType['items'] = cart.items;

		newListItems.forEach((currentItem, index) => {
			if (currentItem.id === item.id && arraysEqual(currentItem.options, item.options) && currentItem.unityValue === item.unityValue) {
				if (type === 'SUM') {
					newListItems[index].qtd++;
				} else {
					newListItems[index].qtd--;
				}
			}
		});

		setCart({
			items: newListItems,
			tot: calcQtd(item, type),
		});
	}

	return (
		<CartContext.Provider
			value={{
				cart,
				Add,
				Remove,
				ChangeQtd,
				clearCart,
			}}>
			{children}
		</CartContext.Provider>
	);
}

export function useCart() {
	const context = useContext(CartContext);

	return context;
}
