import { useAppSelector } from '@/redux/hooks';
import { Button, Modal } from '@mui/material';
import React from 'react';
import QRCode from 'react-qr-code';

type ModalType = {
  open: boolean;
  index: number;
  handleClose(): void;
  setSelectIndex: React.Dispatch<React.SetStateAction<number>>;
};

export default function ModalQrCode({
  open,
  handleClose,
  index,
  setSelectIndex,
}: ModalType) {
  const { list } = useAppSelector((state) => state.order);

  function goNext() {
    if (list?.conteudo) {
      let changed = false;
      if (index + 1 === list.conteudo[0].produtos.length) {
        list.conteudo[0].produtos.forEach((product, key) => {
          if (!changed) {
            if (product.qrcode) {
              setSelectIndex(key);
              changed = true;
            }
          }
        });
      } else {
        list.conteudo[0].produtos.forEach((product, key) => {
          if (!changed) {
            if (key > index && product.qrcode) {
              setSelectIndex(key);
              changed = true;
            }
          }
        });
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disablePortal={true}
      disableEnforceFocus={true}
      id="modalQrCode"
    >
      <div className="modalContent">
        <div className="boxQrCode">
          <QRCode
            value={
              (list?.conteudo && list?.conteudo[0].produtos[index].qrcode) || ''
            }
          />
        </div>
        <div className="modalInfos">
          <p className="modalTitle">
            {list?.conteudo && list?.conteudo[0].produtos[index].nome}
          </p>
          <p className="modalCodeCall">Código para validação:</p>
          <p className="modalCode">
            {list?.conteudo &&
              list?.conteudo[0].produtos[index].codigo_validacao}
          </p>
        </div>
        {list?.conteudo && list.conteudo[0].produtos.length > 1 && (
          <Button onClick={goNext} className="modalBtn">
            Próximo QRCODE
          </Button>
        )}
      </div>
    </Modal>
  );
}
