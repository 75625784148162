import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { createContext, useEffect, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';
import { ListProductsResponse } from '@/models/listProducts.response';
import { GetClient } from '@/services/delivery/auth';

type MainContextType = {
	setCurrentProduct: React.Dispatch<React.SetStateAction<ListProductsResponse['conteudo'][0] | null>>;
	product: ListProductsResponse['conteudo'][0] | null;
	isStoreOpen: boolean;
	setIsStoreOpen: Dispatch<SetStateAction<boolean>>;
};

const MainContext = createContext<MainContextType>({} as MainContextType);

export function MainDeliveryProvider({ children }: { children: ReactNode }) {
	const dispatch = useAppDispatch();
	const [mainColor, setMainColor] = useState('#41a839');
	const { client } = useAppSelector((state) => state.authDelivery);
	const [isStoreOpen, setIsStoreOpen] = useState(true);
	const [product, setCurrentProduct] = useState<ListProductsResponse['conteudo'][0] | null>(null);

	useEffect(() => {
		const url = window.location.href;
		const alias = url.split('/')[2].split('.')[0].includes('cardapio-')
			? url.split('/')[2].split('.')[0].replace('cardapio-', '')
			: url.split('/')[2].split('.')[0].includes('cardapio')
			? url.split('/')[2].split('.')[1]
			: url.split('/')[2].split('.')[0];

		(async () => {
			await dispatch(GetClient({ alias: alias }));
		})();
	}, []);

	useEffect(() => {
		if (client && client.config_cliente.cor) {
			setMainColor('#' + client.config_cliente.cor);
		}
	}, [client]);

	return (
		<MainContext.Provider
			value={{
				product: product,
				setCurrentProduct: setCurrentProduct,
				isStoreOpen: isStoreOpen,
				setIsStoreOpen: setIsStoreOpen,
			}}>
			<style jsx global>{`
				:root {
					--main-color: ${mainColor};
				}
			`}</style>
			{children}
		</MainContext.Provider>
	);
}

export function useMain() {
	const context = useContext(MainContext);

	return context;
}
