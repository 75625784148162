import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { RatingRequest } from '@/models/rating.request';
import { RatingResponse } from '@/models/rating.response';
import { FinishedOrderResponse } from '@/models/finishedOrder.response';

export const FinishedOrder = createAsyncThunk(
  'success/FinishedOrder',
  async (orderCode: number) => {
    const url = `/lista-pedido-finalizado/${orderCode}`;
    const response = await api
      .get(url)
      .then((r): FinishedOrderResponse => r.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const PostRating = createAsyncThunk(
  'success/PostRating',
  async (request: RatingRequest) => {
    const response = await api
      .post(`/efetuar-avaliacao`, request)
      .then((r): RatingResponse => r.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
