/* eslint-disable class-methods-use-this */
const SessionStorage = {
  SetQrCode(qrcode: string) {
    sessionStorage.setItem('@ZeroFila:qrcode', qrcode);
  },

  GetQrCode(): string | null {
    const data = sessionStorage.getItem('@ZeroFila:qrcode');
    if (data) {
      return data;
    } else {
      return null;
    }
  },
};

export default SessionStorage;
