import { createSlice } from '@reduxjs/toolkit';
import { DoLoginFidelity } from '@/services/fidelity/auth';
import UserStorage from '@/storages/user-storage';
import router from 'next/router';
import { DoRegisterFidelity } from '@/services/fidelity/register';
import { LocationFidelityResponse } from '@/models/locationFidelity.response';
import { LocationFidelity } from '@/services/fidelity/location';

const initialState = {
  loading: true,
  locationResponse: <LocationFidelityResponse | null>null,
  requestError: <null | string>null,
};

const locationFidelitySlice = createSlice({
  name: 'locationFidelity',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
    .addCase(LocationFidelity.pending, (state) => {
      state.requestError = null;
      state.locationResponse = null;
    })
    .addCase(LocationFidelity.fulfilled, (state, { payload }) => {
      if (payload) {
        if (payload.retorno) {
         state.locationResponse = payload
         state.loading = false
        } else {
          state.requestError = payload.mensagem;
        }
      }
    });
  },
});

export const { reset } = locationFidelitySlice.actions;
export default locationFidelitySlice.reducer;
