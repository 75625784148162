import { CartType } from '@/models/cartModel';
import { LoginResponse } from '@/models/login.response';
import { ClientModel } from '@/models/clientModel';
import { format, parse } from 'date-fns';

const UserStorage = {
	qrcode: '',

	SetQrcode(qrCode: string) {
		if (this.qrcode) {
			localStorage.setItem('@ZeroFila:QRCode', JSON.stringify({ qrCode, date: format(new Date(), 'dd-MM-yyyy hh:mm:ss') }));
		}
		this.qrcode = qrCode;
	},

	SetLogin(login: LoginResponse) {
		if (this.qrcode) {
			localStorage.setItem('@ZeroFila:login', JSON.stringify(login));
		}
	},

	SetFidelityLogin(userId: number) {
		localStorage.setItem('@ZeroFila:login:fidelidade', JSON.stringify(userId));
	},

	SetQueueLogin(userId: number | null) {
		localStorage.setItem('@ZeroFila:login:queue', JSON.stringify(userId));
	},

	GetQrCode() {
		const data = localStorage.getItem('@ZeroFila:QRCode');
		if (data) {
			const toCheck = JSON.parse(data);
			this.qrcode = parse(toCheck.date, 'dd-MM-yyyy hh:mm:ss', new Date()).getTime() < new Date().getTime() + 1080000 ? toCheck.qrCode : '';
			if (!this.qrcode) {
				localStorage.removeItem('@ZeroFila:QRCode');
				localStorage.removeItem('@ZeroFila:login');
			} else {
				return toCheck.qrCode;
			}
		}
	},

	GetFidelityLogin(): number | null {
		const data = localStorage.getItem('@ZeroFila:login:fidelidade');

		if (data) {
			return JSON.parse(data);
		} else {
			return null;
		}
	},

	GetQueueLogin(): number | null {
		const data = localStorage.getItem('@ZeroFila:login:queue');

		if (data) {
			return JSON.parse(data);
		} else {
			return null;
		}
	},

	GetLogin(): LoginResponse | null {
		this.GetQrCode();
		if (this.qrcode) {
			const data = localStorage.getItem('@ZeroFila:login');
			if (data) {
				return JSON.parse(data);
			} else {
				return null;
			}
		}
    return null;
	},

	SetClientPre(client: ClientModel) {
		if (this.qrcode) {
			localStorage.setItem('@ZeroFila:clientPre' + this.qrcode, JSON.stringify(client));
		}
	},

	GetClientPre(qrCode: string): ClientModel | null {
		const data = localStorage.getItem('@ZeroFila:clientPre' + qrCode);
		if (data) {
			return JSON.parse(data);
		} else {
			return null;
		}
	},

	RemoveClientPre() {
		localStorage.removeItem('@ZeroFila:clientPre' + this.qrcode);
	},

	SetCart(cart: CartType) {
		if (this.qrcode) {
			localStorage.setItem('@ZeroFila:cart' + this.qrcode, JSON.stringify(cart));
		}
	},

	GetCart(qrCode: string): CartType | null {
		const data = localStorage.getItem('@ZeroFila:cart' + qrCode);

		if (data) {
			return JSON.parse(data);
		} else {
			return null;
		}
	},

	SetTimePayment(time: string) {
		if (this.qrcode) {
			localStorage.setItem('@ZeroFila:timePayment' + this.qrcode, time);
		}
	},

	GetTimePayment(qrCode: string) {
		const data = localStorage.getItem('@ZeroFila:timePayment' + qrCode);
		if (data) {
			return +data;
		} else {
			return null;
		}
	},

	RemoveTimePayment() {
		localStorage.removeItem('@ZeroFila:timePayment' + this.qrcode);
	},

	SetPix(data: { pixKey: string; transitionId: number }) {
		if (this.qrcode) {
			localStorage.setItem('@ZeroFila:pix' + this.qrcode, JSON.stringify(data));
		}
	},

	GetPix(qrCode: string): { pixKey: string; transitionId: number } | null {
		const data = localStorage.getItem('@ZeroFila:pix' + qrCode);
		if (data) {
			return JSON.parse(data);
		} else {
			return null;
		}
	},

	RemovePix() {
		localStorage.removeItem('@ZeroFila:pix' + this.qrcode);
	},

	SetOrderId(orderId: number) {
		if (this.qrcode) {
			localStorage.setItem('@ZeroFila:orderId' + this.qrcode, JSON.stringify(orderId));
		}
	},

	GetOrderId(qrCode: string): number | null {
		const data = localStorage.getItem('@ZeroFila:orderId' + qrCode);
		if (data) {
			return JSON.parse(data);
		} else {
			return null;
		}
	},
};

export default UserStorage;
