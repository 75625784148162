const CommonMask = {
	cpf(value: string | undefined) {
		if (!value) return;
		value = value?.replace(/\D/g, '');
		value = value?.replace(/(\d)(\d{2})$/, '$1-$2');
		value = value?.replace(/(?=(\d{3})+(\D))\B/g, '.');
		return value;
	},
	cnpj(value: string) {
		value = value?.replace(/\D/g, '');
		value = value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
		return value;
	},
	cep(value: string) {
		value = value?.replace(/\D/g, '');
		value = value?.replace(/^(\d{5})(\d)/, '$1-$2');
		return value;
	},
	phone(value: string | undefined) {
    try{
      if (!value) return;
      value.toString();
      if (value.length === 0) return;
      value = value?.replace(/\D/g, '');
      value = value?.replace(/^(\d{2})(\d)/g, '($1) $2');
      value = value?.replace(/(\d)(\d{4})$/, '$1-$2');
      return value;
    } catch (e) {
      return;
    }
	},
	currency(value: string) {
		if (value === '0') {
			value = '0,00';
		} else {
			value = value?.replace(/\D/g, '');
			value = value?.replace(/(\d)(\d{2})$/, '$1,$2');
			value = value?.replace(/(?=(\d{3})+(\D))\B/g, '.');
		}
		return value;
	},
	currencyUS(value: string) {
		if (value === '0') {
			value = '0.00';
		} else {
			value = value?.replace(/\D/g, '');
			value = value?.replace(/(\d)(\d{2})$/, '$1.$2');
			value = value?.replace(/(?=(\d{3})+(\D))\B/g, ',');
		}
		return value;
	},
	currencyK(value: number) {
		if (value > 999 && value < 1000000) {
			return `R$${(value / 1000).toFixed(1).replace('.', ',')}K`; // convert to K for number from > 1000 < 1 million
		}
		if (value >= 1000000) {
			return `R$${(value / 1000000).toFixed(1).replace('.', ',')}KK`; // convert to M for number from > 1 million
		}
		if (value <= 999) {
			const maskNumber = `R$${CommonMask.currency(value.toFixed(2).toString())}`;
			return maskNumber; // if value < 1000, nothing to do
		}

		return value;
	},
	percent(value: string) {
		if (value === '0') {
			value = '0,00';
		} else {
			value = value?.replace('.', ',');
			value = value?.replace(/^(\d)(\d{2})$/, '$1,$2');
		}
		return value;
	},
	card(value: string) {
		value = value?.replace(/\D/g, '');
		value = value?.replace(/^(\d{4})(\d)/g, '$1 $2');
		value = value?.replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3');
		value = value?.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
		return value;
	},
	birthday(value: string) {
		value = value?.replace(/\D/g, '');
		let day = value?.slice(0, 2);
		let month = value?.slice(2, 4);
		let year = value?.slice(4, 8);

		if (+day > 31) {
			day = '31';
		}
		if (+month > 12) {
			month = '12';
		}
		if (+year > new Date().getFullYear()) {
			year = new Date().getFullYear().toString();
		}
		if (year.length === 4 && +year < new Date().getFullYear() - 130) {
			year = (new Date().getFullYear() - 130).toString();
		}
		if (day && month && year) {
			return day + '/' + month + '/' + year;
		}
		if (day && month) {
			return day + '/' + month;
		}
		if (day) {
			return day;
		}
		return value;
	},
};

export default CommonMask;
