import React from 'react';

export default function PhoneIcon() {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7299 1.71L8.65987 0.870002C7.84987 0.540002 6.91987 0.930002 6.58987 1.75L5.95987 3.29C5.62987 4.1 6.01987 5.03 6.83987 5.37L9.12987 6.3C8.88987 7.45 8.51987 8.8 7.93987 10.26C7.28987 11.91 6.53987 13.27 5.85987 14.36L3.56987 13.43C2.74987 13.1 1.81987 13.49 1.48987 14.31L0.85987 15.85C0.52987 16.67 0.91987 17.6 1.73987 17.93L3.80987 18.77L3.84987 18.79C6.07987 19.69 8.64987 18.82 9.80987 16.72C10.5899 15.31 11.3599 13.73 12.0699 11.96C12.7099 10.36 13.2099 8.83 13.5899 7.41C14.2199 5.08 12.9999 2.65 10.7699 1.74L10.7299 1.71Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
