import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { FuncionariosFidelityResponse, RegisterFidelityResponse } from '@/models/registerFidelity.response';

export const DoRegisterFidelity = createAsyncThunk(
  'auth/DoRegisterFidelity',
  async (request: any) => {
    const response = await api
      .post('/cadastra-usuario', request)
      .then((r): RegisterFidelityResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const ColaboratorsFidelity = createAsyncThunk(
  'ColaboratorsFidelity',
  async () => {
    const response = await api
      .get('/funcionarios')
      .then((r): FuncionariosFidelityResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
