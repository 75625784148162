import CheckIcon from '@/assets/svg/Check';
import { usePages } from '@/contexts/pages';
import CommonMask from '@/helpers/masks';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { FinishedOrder } from '@/services/success';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Slide,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import ModalQrCode from './components/ModalQrCode';
import { useMain } from '@/contexts/main';
import RatingComp from '@/components/Rating';
import { TransitionProps } from '@mui/material/transitions';
import { useCart } from '@/contexts/cart';
import ModalInfo from '@/components/ModalInfo';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessPage() {
  const { openSuccessPage } = usePages();
  const { responseSuccess, requestError, loading } = useAppSelector(
    (state) => state.success
  );
  const { responseLogin } = useAppSelector((state) => state.auth);
  const { clearCart } = useCart();
  const { storageLogin } = useMain();
  const [alias, setAlias] = useState('');
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const personalData = responseLogin || storageLogin;

  function onDismiss() {
    location.reload();
  }

  useEffect(() => {
    if (openSuccessPage) {
      clearCart();
      const url = window.location.href;
      const newAlias = url.split('/')[2].split('.')[0];
      setAlias(newAlias);
      (async () => {
        if (personalData) {
          await dispatch(FinishedOrder(personalData.comanda_id));
        }
      })();
    }
  }, [openSuccessPage]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  function handleModal(state: boolean) {
    setShowSnack(state);
  }

  useEffect(() => {
    if (requestError) {
      setSnackMsg(requestError);
      setShowSnack(true);
    }
  }, [requestError]);

  return (
    <Dialog
      fullScreen
      open={openSuccessPage}
      onClose={onDismiss}
      TransitionComponent={Transition}
      id="successPage"
      scroll="paper"
      className="customDialog"
      disableScrollLock
    >
      <DialogContent>
        <section className="container">
          <div className="header">
            <div className="icon">
              <CheckIcon />
            </div>
            <h1 className="title">Pagamento realizado com sucesso</h1>
            <p className="subtitle">Você pagou a conta total em {alias}</p>
          </div>
          {loading ? (
            <div className="loadingDefault">
              <CircularProgress className="loading" />
            </div>
          ) : (
            <>
              <div className="card">
                <div className="row">
                  <p className="text">
                    Comanda {responseSuccess?.conteudo.nr_comanda}
                  </p>
                  <span className="tag">pago</span>
                </div>
                <div className="row">
                  <p className="priceTitle">Você pagou</p>
                  {responseSuccess && (
                    <p className="price">
                      <span>R$:</span>{' '}
                      {CommonMask.currency(
                        responseSuccess.conteudo.valor_pago.toFixed(2)
                      )}
                    </p>
                  )}
                </div>
                <div className="row">
                  <p className="text">
                    {responseSuccess?.conteudo.mensagem_validacao}
                  </p>
                  {responseSuccess && (
                    <Button
                      className="btnQrCode"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <div className="boxQrCode">
                        <QRCode value={responseSuccess.conteudo.qrcode} />
                      </div>
                    </Button>
                  )}
                </div>
              </div>
              {responseSuccess?.conteudo.link ? (
                <>
                  <p className="linkMsg">
                    {responseSuccess.conteudo.mensagem_geral}
                  </p>
                  <a href={responseSuccess.conteudo.link} target="_blank">
                    <Button variant="contained" className="btnLink">
                      {responseSuccess.conteudo.texto_botao}
                    </Button>
                  </a>
                </>
              ) : (
                <RatingComp />
              )}
            </>
          )}
        </section>
      </DialogContent>

      <div className="footerBlock">
        <Button
          variant="contained"
          className="btnFooter"
          onClick={onDismiss}
          disabled={loading}
        >
          Voltar para o cardápio
        </Button>
      </div>

      <ModalQrCode
        value={responseSuccess?.conteudo.qrcode || ''}
        open={open}
        handleClose={handleCloseModal}
      />
      <ModalInfo open={showSnack} handler={handleModal} message={snackMsg}/> 
    </Dialog>
  );
}
