import { useAppDispatch } from '@/redux/hooks';
import { Button, Modal } from '@mui/material';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import React from 'react';

type CompType = {
	open: boolean;
	setOpen: Function;
	errorMsg: string;
	reset?: ActionCreatorWithoutPayload<'payment/reset'>;
};

export default function ModalError({ open, setOpen, errorMsg, reset }: CompType) {
	const dispatch = useAppDispatch();

	return (
		<Modal open={open} id="modalPix" disablePortal disableEnforceFocus disableAutoFocus disableScrollLock style={{ zIndex: 999 }}>
			<div className="pixContent">
				<div>
					<p className="statusTitle">OPS...</p>
					<br />
					<p className="pixTitle">{errorMsg}</p>
					<br />
					<Button
						variant="contained"
						className="btnPix"
						onClick={() => {
							if (reset) {
								dispatch(reset());
							}
							setOpen(false);
						}}>
						Ok
					</Button>
				</div>
			</div>
		</Modal>
	);
}
