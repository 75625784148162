import { createSlice } from '@reduxjs/toolkit';
import { GerFilters, PostSearch } from '@/services/search';
import { ListFilterResponse } from '@/models/listFilter.response';
import { ListProductsResponse } from '@/models/listProducts.response';

const initialState = {
  listFilters: <ListFilterResponse | null>null,
  resultSearch: <ListProductsResponse | null>null,
  loading: false,
  requestError: <null | string>null,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GerFilters.pending, (state) => {
        state.requestError = null;
        state.listFilters = null;
        state.loading = true;
      })
      .addCase(GerFilters.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.listFilters = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(PostSearch.pending, (state) => {
        state.requestError = null;
        state.resultSearch = null;
        state.loading = true;
      })
      .addCase(PostSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.resultSearch = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      });
  },
});

export const { reset } = searchSlice.actions;
export default searchSlice.reducer;
