import { createSlice } from '@reduxjs/toolkit';
import {
  GetOrder,
  ListOrder,
  ListSchedules,
  PostOrder,
  StatusOrder,
} from '@/services/delivery/order';
import { MakeOrderResponse } from '@/models/delivery/makeOrder.response';
import { GetOrderResponse } from '@/models/delivery/getOrder.response';
import { StatusOrderResponse } from '@/models/delivery/statusOrder.response';
import { ListSchedulesResponse } from '@/models/delivery/listSchedules.response';
import { ListOrderResponse } from '@/models/delivery/listOrder.response';

const initialState = {
  orderResponse: <MakeOrderResponse | null>null,
  orderStatus: <StatusOrderResponse | null>null,
  order: <GetOrderResponse | null>null,
  schedules: <ListSchedulesResponse | null>null,
  listOrders: <ListOrderResponse | null>null,
  loading: false,
  requestError: <null | string>null,
};

const orderDeliverySlice = createSlice({
  name: 'orderDelivery',
  initialState,
  reducers: {
    resetOrder: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostOrder.pending, (state) => {
        state.requestError = null;
        state.orderResponse = null;
        state.loading = true;
      })
      .addCase(PostOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.orderResponse = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(StatusOrder.pending, (state) => {
        state.requestError = null;
      })
      .addCase(StatusOrder.fulfilled, (state, { payload }) => {
        if (payload) {
          if (payload.retorno) {
            state.orderStatus = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(GetOrder.pending, (state) => {
        state.requestError = null;
        state.order = null;
        state.loading = true;
      })
      .addCase(GetOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.order = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(ListSchedules.pending, (state) => {
        state.requestError = null;
        state.schedules = null;
        state.loading = true;
      })
      .addCase(ListSchedules.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.schedules = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(ListOrder.pending, (state) => {
        state.requestError = null;
        state.listOrders = null;
        state.loading = true;
      })
      .addCase(ListOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.listOrders = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      });
  },
});

export const { resetOrder } = orderDeliverySlice.actions;
export default orderDeliverySlice.reducer;
