import React from 'react';

export default function Star() {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1 17.85L5.10531 21.5273L6.44116 13.7387L0.782379 8.2227L8.60263 7.08635L12.1 0L15.5973 7.08635L23.4175 8.2227L17.7587 13.7387L19.0946 21.5273L12.1 17.85Z"
        fill="currentColor"
      />
    </svg>
  );
}
