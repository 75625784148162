import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import NotFoundComp from '@/components/NotFound';
import { useMain } from '@/contexts/main';
import CommonMask from '@/helpers/masks';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import React, { useEffect, useState } from 'react';
import { ListOrder } from '@/services/order';
import FooterBottomSheet from '@/components/FooterBottomSheet';
import { usePages } from '@/contexts/pages';
import { Box, Checkbox, CircularProgress, Dialog, DialogContent, FormControl, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import ModalInfo from '@/components/ModalInfo';
import { resetOrderResponse, setSelectedItens } from '@/redux/reducers/order';
import useTranslation from '@/hooks/cardapioUseTranslation';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderPage() {
	const dispatch = useAppDispatch();
	const { storageLogin } = useMain();
	const { openOrder, setOpenOrder, setOpenPayment } = usePages();
	const { responseLogin, client, loginFields } = useAppSelector(state => state.auth);
	const { loading, list, orderResponse, requestError, selectedItens } = useAppSelector(state => state.order);
	const personalData = responseLogin || storageLogin;
	const [showSnack, setShowSnack] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');
	const { returnTranslation } = useTranslation();

	function onDismiss() {
		setOpenOrder(false);
	}

	async function getOrder() {
		if (personalData) {
			await dispatch(ListOrder(personalData.comanda_id));
		}
	}

	useEffect(() => {
		if ((orderResponse || personalData) && client && openOrder) {
			getOrder();
		}
	}, [openOrder]);

	useEffect(() => {
		if (requestError) {
			setSnackMsg(requestError);
			setShowSnack(true);
		}
	}, [requestError]);

	useEffect(() => {
		if (list) {
			if (orderResponse?.mensagem) {
				setSnackMsg(orderResponse.mensagem);
				setShowSnack(true);
			}
		}
	}, [list]);

	function handleModal(state: boolean) {
		dispatch(resetOrderResponse());
		setShowSnack(state);
	}

	const handleProductChange = (item: any) => {
		// Verifica se o item já está no array selectedItens
		if (selectedItens.includes(item)) {
			// Se o item já existir, exibe um aviso
			console.log('O produto já existe no array.', item);
			// Remove o produto
			const updatedSelectedIten = selectedItens.filter(i => i !== item);
			dispatch(setSelectedItens(updatedSelectedIten));
			console.log('Produto removido:', item);
		} else {
			// Se o item não existir, adiciona-o ao array
			const updatedSelectedItens = [...selectedItens, item];
			dispatch(setSelectedItens(updatedSelectedItens));
			console.log('Produto adicionado:', item);
		}
	};

	return (
		<Dialog fullScreen open={openOrder} onClose={onDismiss} TransitionComponent={Transition} id="order" scroll="paper" className="customDialog" disableScrollLock>
			<HeaderBottomSheet title={returnTranslation('orderNav')} onDismiss={onDismiss} />

			<DialogContent>
				<div>
					{loading ? (
						<div className="loadingDefault">
							<CircularProgress className="loading" />
						</div>
					) : (
						<div className="content">
							{list?.conteudo && (
								<ul className="infos">
									{list.conteudo[0].nome && <li className="info">Nome: {list.conteudo[0].nome}</li>}
									{list.conteudo[0].nr_comanda && <li className="info">Comanda: {list.conteudo[0].nr_comanda}</li>}
									{list.conteudo[0].nr_mesa && <li className="info">Mesa: {list.conteudo[0].nr_mesa}</li>}
									{/* expocassia */}
									{client?.config_cliente?.agrupa_itens_mesa && !loginFields?.config_qrcode?.pre_pago && loginFields?.config_qrcode?.controle_qrcode === 'M' && (
										<li className="info" style={{ textAlign: 'center', marginTop: '1vh', fontSize: '16px', marginBottom: '-2vh' }}>
											Selecione os itens que deseja pagar.
										</li>
									)}
									{client?.config_cliente.texto_comanda && (
										<li className="info">
											<br />
											Texto: {client.config_cliente.texto_comanda}
										</li>
									)}
								</ul>
							)}
							{list?.conteudo && list.conteudo[0].produtos.length > 0 ? (
								<ul className="list">
									{list.conteudo[0].produtos
										.filter(item => item.item_pago !== 1)
										.map((item, index) => (
											<li className="item" key={index}>
												<Box sx={{ width: '100vw', display: 'flex', flexDirection: 'row' }}>
													<div className="itemImg" style={{ backgroundImage: `url(${item.imagem})`, marginRight: '1vw' }}></div>
													<div className="itemContent">
														<p className="itemPrice">
															<span>R$:</span> {CommonMask.currency(item.total.toFixed(2).toString())}
														</p>
														<p className="itemTitle">{item.nome}</p>
														{item.adicionais && <p className="itemOptions">{item.adicionais.map(option => option.nome).join(', ')}</p>}
													</div>
													<div style={{ width: 'auto', display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
														{client?.config_cliente?.agrupa_itens_mesa && !loginFields?.config_qrcode?.pre_pago && loginFields?.config_qrcode?.controle_qrcode === 'M' && (
															<Checkbox
																checked={selectedItens.includes(item)}
																onChange={() => {
																	handleProductChange(item);
																}}
																inputProps={{ 'aria-label': 'controlled' }}
																color="default"
															/>
														)}
														<p className="itemTitle">Qtd: {item.quantidade}</p>
													</div>
												</Box>
											</li>
										))}
									{client?.config_cliente?.agrupa_itens_mesa && !loginFields?.config_qrcode?.pre_pago && loginFields?.config_qrcode?.controle_qrcode === 'M' && list?.conteudo[0].produtos.some(item => item.item_pago === 1) && <ul className="infos" />}
									{client?.config_cliente?.agrupa_itens_mesa && !loginFields?.config_qrcode?.pre_pago && loginFields?.config_qrcode?.controle_qrcode === 'M' && list?.conteudo[0].produtos.some(item => item.item_pago === 1) && (
										<li className="info" style={{ textAlign: 'center', marginTop: '1vh', fontSize: '16px', marginBottom: '-2vh' }}>
											Itens pagos.
										</li>
									)}

									<ul className="list">
										{list.conteudo[0].produtos
											.filter(item => item.item_pago === 1)
											.map((item, index) => (
												<li className="item" key={index}>
													<Box sx={{ width: '100vw', display: 'flex', flexDirection: 'row' }}>
														<div className="itemImg" style={{ backgroundImage: `url(${item.imagem})`, marginRight: '1vw' }}></div>
														<div className="itemContent">
															<p className="itemPrice">
																<span>R$:</span> {CommonMask.currency(item.total.toFixed(2).toString())}
															</p>
															<p className="itemTitle">{item.nome} </p>
															{item.adicionais && <p className="itemOptions">{item.adicionais.map(option => option.nome).join(', ')}</p>}
														</div>
														<div style={{ width: 'auto', display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
															<p className="itemTitle">Qtd: {item.quantidade}</p>
														</div>
													</Box>
												</li>
											))}
									</ul>
								</ul>
							) : (
								<NotFoundComp txt={returnTranslation('noProductOrder')} />
							)}
						</div>
					)}
				</div>
			</DialogContent>

			{list?.conteudo && client && client.config_cliente.pagamento_comanda && (
				<FooterBottomSheet
					btnTxt="Pagar comanda"
					tot={list?.conteudo[0].total || 0}
					qtd={list?.conteudo[0].produtos.length || 0}
					disabled={list.conteudo[0].produtos.length < 1}
					onClick={() => {
						onDismiss();
						setOpenPayment(true);
					}}
				/>
			)}
			<ModalInfo open={showSnack} handler={handleModal} message={snackMsg} />
		</Dialog>
	);
}
