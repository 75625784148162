import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { ListFilterResponse } from '@/models/listFilter.response';
import { SearchRequest } from '@/models/search.request';
import { ListProductsResponse } from '@/models/listProducts.response';

export const GerFilters = createAsyncThunk(
  'searchDelivery/GerFilters',
  async () => {
    const response = await api
      .get(`/lista-filtros`)
      .then((r): ListFilterResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const PostSearch = createAsyncThunk(
  'searchDelivery/PostSearch',
  async (request: SearchRequest) => {
    const response = await api
      .post(`/busca`, request)
      .then((r): ListProductsResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
