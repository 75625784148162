import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { CustomerRequest } from '@/models/delivery/customer.request';
import { CustomerResponse } from '@/models/delivery/customer.response';
import { RegisterAddressRequest } from '@/models/delivery/registerAddress.request';
import { RegisterAddressResponse } from '@/models/delivery/registerAddress.response';
import { GetAddressRequest } from '@/models/delivery/getAddress.request';
import { GetAddressResponse } from '@/models/delivery/getAddress.response';
import { DeleteAddressResponse } from '@/models/delivery/deleteAddress.response';

export const GetCustomerData = createAsyncThunk(
  'user/GetCustomerData',
  async (request: CustomerRequest) => {
    const response = await api
      .post(`/busca-dados-usuario`, request)
      .then((r): CustomerResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const PostAddress = createAsyncThunk(
  'user/PostAddress',
  async ({
    request,
    editAddressId,
  }: {
    request: RegisterAddressRequest;
    editAddressId?: number;
  }) => {
    let url;
    if (editAddressId) {
      url = `/insere-endereco-usuario/${editAddressId}`;
    } else {
      url = `/insere-endereco-usuario`;
    }
    const response = await api
      .post(url, request)
      .then((r): RegisterAddressResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const GetAddress = createAsyncThunk(
  'user/GetAddress',
  async (request: GetAddressRequest) => {
    const response = await api
      .post('/busca-endereco', request)
      .then((r): GetAddressResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const DeleteAddress = createAsyncThunk(
  'user/DeleteAddress',
  async (idAddress: number | string) => {
    const url = `/deleta-endereco/${idAddress}`;
    const response = await api
      .delete(url)
      .then((r): DeleteAddressResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
