import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { ListGroupsResponse } from '@/models/listGroups.response';
import { CallWaiterRequest } from '@/models/callWaiter.request';
import { CallWaiterResponse } from '@/models/callWaiter.response';
import { listaGrupoDepartamentosRequest } from '@/models/listaGruposDepartamento.request';

export const ListGroups = createAsyncThunk('home/ListGroups', async (request: listaGrupoDepartamentosRequest) => {
	const response = await api
		.post(`/lista-grupos-departamentos`, request)
		.then((r): ListGroupsResponse => r.data)
		.catch((error) => {
			return error.response.data;
		});
	return response;
});

export const CallWaiter = createAsyncThunk('home/callWaiter', async (request: CallWaiterRequest) => {
	const response = await api
		.post(`/chamar-garcom`, request)
		.then((r): CallWaiterResponse => r.data)
		.catch((error) => {
			return error.response.data;
		});
	return response;
});

export const setOrder = createAsyncThunk('home/setOrder', async (request: string) => {
	return request;
});
