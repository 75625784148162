import { EventsCardModel } from '@/models/eventsCardModel';
import { EventsClientModelResponse } from '@/models/eventsClientModel.response';
import { EventEventModel, EventModelResponse, EventsCartModel } from '@/models/eventsEventModel.response';
import { EventsLoginModel } from '@/models/eventsLoginModel';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	showTimes: false,
};

const eventsSlice = createSlice({
	name: 'delivery',
	initialState,
	reducers: {
		reset: () => initialState,
		changeShowTimes(state, action) {
			state.showTimes = action.payload;
		},
	},
});

export const { reset, changeShowTimes } = eventsSlice.actions;
export default eventsSlice.reducer;
