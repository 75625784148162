import React from 'react';

export default function LocationIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4745 6.4746C16.4745 10.0241 13.0092 14.2955 11.5894 15.8837C11.2766 16.2326 10.7231 16.2326 10.4103 15.8837C9.00253 14.2834 5.53729 10.0241 5.53729 6.4746C5.53729 3.45455 7.99184 1 11.0119 1C14.0319 1 16.4745 3.45455 16.4745 6.4746Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0071 6.73684C13.0797 5.62943 12.2408 4.67287 11.1334 4.60029C10.026 4.52771 9.06941 5.36661 8.99683 6.47402C8.92425 7.58143 9.76315 8.53799 10.8706 8.61057C11.978 8.68315 12.9345 7.84425 13.0071 6.73684Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 12.7554L1.78592 18.5008C1.40723 19.4835 2.13249 20.5402 3.18558 20.5402H18.8144C19.8675 20.5402 20.5928 19.4835 20.2141 18.5008L18 12.7554"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
