import { Skeleton, Typography } from '@mui/material';

export default function SkeletonHome() {
	return (
		<div id="home" style={{marginTop:'0vh', padding:'0em'}}  >
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom:'2.4vh' }}>
				<Skeleton className="card" variant="rectangular" height={50} width={250} sx={{ marginBottom: '2vh' }} />
			</div>
			<div className="list" style={{padding:'1em'}} >
				<Skeleton className="card" variant="rectangular" height={150} />
				<Skeleton className="card" variant="rectangular" height={150} />
				<Skeleton className="card" variant="rectangular" height={150} />
				<Skeleton className="card" variant="rectangular" height={150} />
				<Skeleton className="card" variant="rectangular" height={150} />
				<Skeleton className="card" variant="rectangular" height={150} />
			</div>
		</div>
	);
}
