import { MessageObjectModel } from '@/models/messageObject.model';
import { PosCartModel } from '@/models/posCartModel';
import { PosDepartamento, PosLoginResponse, PosProduto, PosRestaurantes } from '@/models/posLogin.response';
import { PosSaleResponse } from '@/models/posSaleResponse';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	activeDepartment: {} as PosDepartamento,
	cart: [] as PosCartModel[],
	drawerProduct: {} as PosProduto,
	colors: { cor_fontes: '', cor_fontes_riscadas: '', cor_fundo_geral: '', cor_fundo_icones_selecionados: '' },
	errorText: '',
	isLoggedIn: false,
	startPosTransaction: false,
	clientSelected: {} as PosRestaurantes,
	startFinishTransaction: false,
	toCancelTransactionsNumber: 0,
	buttonReturnMulti: true,
	loginData: {} as PosLoginResponse,
	transactionFromModalCancel: false,
	openDrawerOptional: false,
	posToCancelTransactions: [] as { cupom: string; date: string; time: string; id: string; originalCode: string; value: string }[],
	posDailyLog: [] as PosDailySale[],
	posHistory: [] as PosPedidoModel[],
	page: 0,
	posModalOnSelection: (value: string) => {},
	posModalOpen: false,
	posModalQrCode: '',
	posPixCancelation: false,
	posModalText: '',
	posModalReturnBtn: true,
	posErrorMessage: '',
	cupomCode: '',
	posShowPaymentImage: false,
	posAudio: false,
	posModalTitle: '',
	posModalType: 'ok' as 'ok' | 'options' | 'YN' | 'number' | 'text' | 'onlyText' | 'multiline',
	posSEModalType: 'success',
	posSEOpen: false,
	posSEText: '',
	posSETitle: '',
	posTefClient: '',
	posTefStore: '',
	pendingTransaction: '',
	posTransactionMessage: '',
	posTransactionTitle: '',
	getInitialPOSTransactionData: <null | string>null,
	posTransactionType: 'iddle',
	saleData: {} as PosSaleResponse,
	tempCartItem: {} as PosCartModel,
};

const eventsSlice = createSlice({
	name: 'pos',
	initialState,
	reducers: {
		addToPosCart: (state, action) => {
			state.cart.push({ ...action.payload, id: state.cart.length > 0 ? state.cart[state.cart.length - 1].id + 1 : 0 });
		},
		changeStartPosTransaction: (state, action) => {
			state.startPosTransaction = action.payload;
		},
		changeColors: (state, action) => {
			state.colors = action.payload;
		},
		changePosTefClient: (state, action) => {
			state.posTefClient = action.payload;
		},
		changePosTefStore: (state, action) => {
			state.posTefStore = action.payload;
		},
		changePosErrorMessage: (state, action) => {
			state.posErrorMessage = action.payload;
		},
		changeActiveDepartment: (state, action) => {
			state.activeDepartment = action.payload;
		},
		changePosTempCartItem: (state, action) => {
			state.tempCartItem = action.payload;
		},
		changePendingTransaction: (state, action) => {
			state.pendingTransaction = action.payload;
		},
		changePosShowPaymentImage: (state, action) => {
			state.posShowPaymentImage = action.payload;
		},
		changePosPixCancelation: (state, action) => {
			state.posPixCancelation = action.payload;
		},
		changeButtonReturnMulti: (state, action) => {
			state.buttonReturnMulti = action.payload;
		},
		changeGetInitialPOSTransactionData: (state, action) => {
			if (state !== null) {
				resetPosTransactionPage();
			}
			state.getInitialPOSTransactionData = action.payload;
		},
		changeClientSelected: (state, action) => {
			state.clientSelected = action.payload;
		},
		changePosCart: (state, action) => {
			state.cart = action.payload;
		},
		changePosDrawerOptional: (state, action) => {
			if (action?.payload) {
				state.openDrawerOptional = true;
				state.drawerProduct = action.payload;
			} else {
				state.openDrawerOptional = false;
				state.drawerProduct = initialState.drawerProduct;
			}
		},
		changePosErrorText: (state, action) => {
			state.errorText = action.payload;
		},
		addNewPosToCancelTransaction: (state, action) => {
			console.log('add');
			const toReturnArray = [...state.posToCancelTransactions];
			toReturnArray.push({ cupom: action.payload, date: '', id: '', originalCode: '', time: '', value: '' });
			state.posToCancelTransactions = toReturnArray;
		},
		changePosToCancelTransaction: (state, action) => {
			const toReturnArray = [...state.posToCancelTransactions];
			toReturnArray[toReturnArray.length - 1] = { ...toReturnArray[toReturnArray.length - 1], ...action.payload };
			state.posToCancelTransactions = toReturnArray;
		},
		resetPosToCancelTransaction: state => {
			state.posToCancelTransactions = [];
		},
		removeFirstToCancelTransaction: state => {
			const toReturnArray = [...state.posToCancelTransactions];
			toReturnArray.shift();
			state.posToCancelTransactions = toReturnArray;
		},
		changePosToCancelTransactionsNumber: (state, action) => {
			state.toCancelTransactionsNumber = action.payload;
		},
		changeTransactionFromModalCancel: (state, action) => {
			state.transactionFromModalCancel = action.payload;
		},
		changeStartFinishTransaction: (state, action) => {
			state.startFinishTransaction = action.payload;
		},
		changePosLoggedIn: (state, action) => {
			state.isLoggedIn = action.payload;
		},
		changePosLoginData: (state, action) => {
			state.loginData = action.payload;
		},
		changePosModalOnSelection: (state, action) => {
			state.posModalOnSelection = action.payload;
		},
		changePosModalOpen: (state, action) => {
			state.posModalOpen = action.payload;
		},
		changePosModalQrCode: (state, action) => {
			state.posModalQrCode = action.payload;
		},
		changePosModalText: (state, action) => {
			state.posModalText = action.payload;
		},
		changePosModalReturnBtn: (state, action) => {
			state.posModalReturnBtn = action.payload;
		},
		changePosModalTitle: (state, action) => {
			state.posModalTitle = action.payload;
		},
		changePosModalType: (state, action) => {
			state.posModalType = action.payload;
		},
		changePosPage: (state, action) => {
			state.page = action.payload;
		},
		changePosCupomCode: (state, action) => {
			state.cupomCode = action.payload;
		},
		changePosSEModalType: (state, action) => {
			state.posSEModalType = action.payload;
		},
		changePosSEOpen: (state, action) => {
			state.posSEOpen = action.payload;
		},
		changePosSEText: (state, action) => {
			state.posSEText = action.payload;
		},
		changePosSETitle: (state, action) => {
			state.posSETitle = action.payload;
		},
		changePosTransactionMessage: (state, action) => {
			state.posTransactionMessage = action.payload;
		},
		changePosTransactionTitle: (state, action) => {
			state.posTransactionTitle = action.payload;
		},
		changePosHistory: (state, action) => {
			state.posHistory = action.payload;
		},
		changePosDailyLog: (state, action) => {
			state.posDailyLog = action.payload;
		},
		changePosTransactionType: (state, action) => {
			state.posTransactionType = action.payload;
		},
		changeSaleRepsonse: (state, action) => {
			state.saleData = action.payload;
		},
		closePosSE: state => {
			state.posSEOpen = false;
			state.posSEText = '';
			state.posSETitle = '';
		},
		playPosAudio: (state, action) => {
			state.posAudio = action.payload;
		},
		reset: () => initialState,
		resetPosModal: state => {
			state.posModalReturnBtn = true;
			state.posModalOpen = false;
			state.posModalText = '';
			state.posModalTitle = '';
			state.posModalType = 'ok';
			state.posModalOnSelection = (value: string) => {};
		},
		resetPosTransactionPage: state => {
			state.posModalQrCode = '';
			state.posTransactionMessage = '';
			state.posTransactionTitle = '';
			state.posTransactionType = 'iddle';
			state.posModalReturnBtn = true;
			state.posModalOpen = false;
			state.posModalText = '';
			state.posModalTitle = '';
			state.posModalType = 'ok';
			state.posModalOnSelection = (value: string) => {};
		},
	},
});

export const {
	addToPosCart,
	changeClientSelected,
	changePosErrorMessage,
	changeActiveDepartment,
	changeButtonReturnMulti,
	changePosCart,
	changePosModalReturnBtn,
	changePosDrawerOptional,
	changePosErrorText,
	changePosHistory,
	changePosDailyLog,
	changePosLoggedIn,
	changePosTefStore,
	changePosTefClient,
	addNewPosToCancelTransaction,
	changePosToCancelTransaction,
	removeFirstToCancelTransaction,
	resetPosToCancelTransaction,
	changePosLoginData,
	changePosModalOnSelection,
	changePosModalOpen,
	changeGetInitialPOSTransactionData,
	changePosCupomCode,
	changePosPixCancelation,
	changePosShowPaymentImage,
	changeStartFinishTransaction,
	changePosModalQrCode,
	resetPosTransactionPage,
	changePosModalText,
	changePosTempCartItem,
	playPosAudio,
	changePosModalTitle,
	changePendingTransaction,
	changePosModalType,
	changePosPage,
	changeTransactionFromModalCancel,
	changePosSEModalType,
	changePosSEOpen,
	changePosSEText,
	changePosSETitle,
	changePosTransactionMessage,
	changePosTransactionTitle,
	changePosTransactionType,
	changeSaleRepsonse,
	changeColors,
	closePosSE,
	changeStartPosTransaction,
	changePosToCancelTransactionsNumber,
	reset,
	resetPosModal,
} = eventsSlice.actions;
export default eventsSlice.reducer;
