import { ListProductsResponse } from '@/models/listProducts.response';
import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

type ProductType = {
  product: ListProductsResponse['conteudo'][0] | null;
};

export default function BannerComp({ product }: ProductType) {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      className="mySwiper"
      navigation={true}
      modules={[Navigation]}
    >
      {product?.imagens.map((banner) => (
        <SwiperSlide key={banner.ordem}>
          <div
            className="bannerItem"
            style={{ backgroundImage: `url(${banner.imagem})` }}
          ></div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
