import React from 'react';

export default function ArrowIcon() {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.39792 0L0 1.60216L4.19384 6.40805L4.19375 6.40816L5.59165 8.00986L6.99 6.40816L6.98974 6.40786L11.1837 1.60216L9.78583 0L5.59187 4.80599L1.39792 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
