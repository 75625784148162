import React from 'react';

export default function HomeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 9.60555C3 8.60249 3.5013 7.6658 4.3359 7.1094L10.3359 3.1094C11.3436 2.4376 12.6564 2.4376 13.6641 3.1094L19.6641 7.1094C20.4987 7.6658 21 8.60249 21 9.60555V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V9.60555Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M7 17C7 15.8954 7.89543 15 9 15V15C10.1046 15 11 15.8954 11 17V21H7V17Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
