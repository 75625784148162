import React from 'react';

export default function PinIcon() {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3901 6.99C13.3901 11.14 9.34011 16.13 7.69011 17.99C7.32011 18.4 6.68011 18.4 6.31011 17.99C4.66011 16.13 0.610107 11.14 0.610107 6.99C0.610107 3.46 3.47011 0.599998 7.00011 0.599998C10.5301 0.599998 13.3901 3.46 13.3901 6.99Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99018 9.55C8.40402 9.55 9.55018 8.40385 9.55018 6.99C9.55018 5.57615 8.40402 4.43 6.99018 4.43C5.57633 4.43 4.43018 5.57615 4.43018 6.99C4.43018 8.40385 5.57633 9.55 6.99018 9.55Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
