// import { ListLinks } from '@/services/links';
// import { ListLinksResponse } from '@/models/listLinks.response';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	text: '',
	open: false,
	loading: false,
	requestError: <null | string>null,
};

const linksSlice = createSlice({
	name: 'alert',
	initialState,
	reducers: {
		reset: () => initialState,
		createAlert(state, action) {
			state.open = true;
			state.text = action.payload;
		},
	},
});

export const { reset, createAlert } = linksSlice.actions;
export default linksSlice.reducer;
