import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '@mui/material';
import { setSelectedItens } from '@/redux/reducers/order';
import { useAppDispatch } from '@/redux/hooks';

type HeaderType = {
	title: string;
	onDismiss(): void;
};

export default function HeaderBottomSheet({ title, onDismiss }: HeaderType) {
	const dispatch = useAppDispatch();
	const onClose = () => {
		onDismiss();
		dispatch(setSelectedItens([]));
	};

	return (
		<div className="headerBottomSheet">
			<Button variant="contained" className="btn" onClick={onClose}>
				<KeyboardArrowDownIcon />
			</Button>
			<h2 className="title">{title}</h2>
		</div>
	);
}
