import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { ListBannersResponse } from '@/models/listBanners.response';
import { ListProductsResponse } from '@/models/listProducts.response';
import { ListDepartmentsResponse } from '@/models/listDepartments.response';
import { SearchClienteResponse } from '@/models/client.response';
import UserStorage from '@/storages/user-storage';
import { useAppSelector } from '@/redux/hooks';

export const GetBanners = createAsyncThunk('menu/GetBanners', async (departmentId?: number | undefined) => {
	let url;
	if (departmentId) {
		url = `/lista-banners/${departmentId}`;
	} else {
		url = '/lista-banners';
	}
	const response = await api
		.get(url)
		.then((r): ListBannersResponse => r.data)
		.catch((error) => {
			return error.response.data;
		});
	return response;
});

export const GetProducts = createAsyncThunk(
	'menu/GetProducts',
	async (information: { departmentId?: string | string[] | undefined; client: SearchClienteResponse | undefined; qrcode_id: number | undefined }) => {
		try {
			const loginData = UserStorage.GetLogin();
			const requestObject = { menor_idade: !!loginData?.menor_idade, logado: !!loginData, qrcode_id: information.qrcode_id };
			let url;
			if (information.departmentId) {
				url = `/lista-produtos/${+information.departmentId}`;
			} else {
				url = '/lista-produtos';
			}

			const response = await api
				.post(url, requestObject)
				.then((r): ListProductsResponse => r.data)
				.catch((error) => {
					return error.response.data;
				});
			return response;
		} catch (error) {
			console.log(error);
		}
	},
);

export const GetDepartments = createAsyncThunk('menu/GetDepartments', async (info: { departmentId?: number | undefined; qrcode_id: number | undefined }) => {
	let url;
	if (info.departmentId) {
		url = `/lista-departamentos/${info.departmentId}`;
	} else {
		url = '/lista-departamentos';
	}

	const response = await api
		.post(url, { qrcode_id: info.qrcode_id })
		.then((r): ListDepartmentsResponse => r.data)
		.catch((error) => {
			return error.response.data;
		});
	return response;
});
