import { FinishedOrderResponse } from '@/models/finishedOrder.response';
import { RatingResponse } from '@/models/rating.response';
import { FinishedOrder, PostRating } from '@/services/success';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  responseSuccess: <FinishedOrderResponse | null>null,
  responseRating: <RatingResponse | null>null,
  loading: false,
  loadingRating: false,
  requestError: <null | string>null,
};

const successSlice = createSlice({
  name: 'success',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(FinishedOrder.pending, (state) => {
        state.requestError = null;
        state.responseSuccess = null;
        state.loading = true;
      })
      .addCase(FinishedOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.responseSuccess = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(PostRating.pending, (state) => {
        state.requestError = null;
        state.responseRating = null;
        state.loadingRating = true;
      })
      .addCase(PostRating.fulfilled, (state, { payload }) => {
        state.loadingRating = false;
        if (payload) {
          if (payload.retorno) {
            state.responseRating = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      });
  },
});

export const { reset } = successSlice.actions;
export default successSlice.reducer;
