import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { ListBannersResponse } from '@/models/listBanners.response';
import { ListProductsResponse } from '@/models/listProducts.response';
import { ListDepartmentsResponse } from '@/models/listDepartments.response';

export const GetBanners = createAsyncThunk('menuDelivery/GetBanners', async () => {
	const response = await api
		.get('/lista-banners')
		.then((r): ListBannersResponse => r.data)
		.catch((error) => {
			return error.response.data;
		});
	return response;
});

export const GetProducts = createAsyncThunk('menuDelivery/GetProducts', async () => {
	const response = await api
		.get('/lista-produtos')
		.then((r): ListProductsResponse => r.data)
		.catch((error) => {
			return error.response.data;
		});
	return response;
});

export const GetDepartments = createAsyncThunk('menuDelivery/GetDepartments', async () => {
	const response = await api
		.get('/lista-departamentos')
		.then((r): ListDepartmentsResponse => r.data)
		.catch((error) => {
			return error.response.data;
		});
	return response;
});
