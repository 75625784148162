import { Button, Modal } from '@mui/material';
import React from 'react';

type Props = {
  open: boolean;
  handler: (state: boolean) => void;
  message: string;
};

export default function ModalInfo({
  open,
  handler,
  message,
}: Props) {

  return (
    <Modal
      open={open}
      id="modalPix"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      disableScrollLock
      style={{ zIndex: 999 }}
    >
      <div className="pixContent">
        <div>
          <br />
          <p className="pixTitle">{message}</p>
          <br />
          <Button
            variant="contained"
            className="btnPix"
            onClick={() => {
              handler(false);
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
}
