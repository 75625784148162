import { CallWaiter, ListGroups, setOrder } from '@/services/home';
import { createSlice } from '@reduxjs/toolkit';
import { ListGroupsResponse } from '@/models/listGroups.response';
import { CallWaiterResponse } from '@/models/callWaiter.response';

const initialState = {
	list: <ListGroupsResponse | null>null,
	responseWaiter: <CallWaiterResponse | null>null,
	loading: false,
	loadingWaiter: false,
	requestError: <null | string>null,
	order: 'DEF',
};

const homeSlice = createSlice({
	name: 'home',
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(ListGroups.pending, (state) => {
				state.requestError = null;
				state.list = null;
				state.loading = true;
			})
			.addCase(ListGroups.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					if (payload.retorno) {
						state.list = payload;
					} else {
						state.requestError = payload.mensagem;
					}
				}
			})
			.addCase(CallWaiter.pending, (state) => {
				state.requestError = null;
				state.responseWaiter = null;
				state.loadingWaiter = true;
			})
			.addCase(CallWaiter.fulfilled, (state, { payload }) => {
				state.loadingWaiter = false;
				if (payload) {
					if (payload.retorno) {
						state.responseWaiter = payload;
					} else {
						state.requestError = payload.mensagem;
					}
				}
			})
			.addCase(setOrder.fulfilled, (state, { payload }) => {
				state.order = payload;
			});
	},
});

export const { reset } = homeSlice.actions;
export default homeSlice.reducer;
