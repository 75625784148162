import { createSlice } from '@reduxjs/toolkit';
import { ListLinks } from '@/services/links';
import { ListLinksResponse } from '@/models/listLinks.response';

const initialState = {
  list: <ListLinksResponse | null>null,
  loading: false,
  requestError: <null | string>null,
};

const linksSlice = createSlice({
  name: 'links',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(ListLinks.pending, (state) => {
        state.requestError = null;
        state.list = null;
        state.loading = true;
      })
      .addCase(ListLinks.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.list = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      });
  },
});

export const { reset } = linksSlice.actions;
export default linksSlice.reducer;
