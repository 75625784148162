import { usePages } from '@/contexts/pages';
import CommonMask from '@/helpers/masks';
import useTranslation from '@/hooks/cardapioUseTranslation';
import { CartType } from '@/models/cartModel';
import { ListProductsResponse } from '@/models/listProducts.response';
import React, { useEffect, useState } from 'react';

type OptionalType = {
  option: ListProductsResponse['conteudo'][0]['opcionais'][0];
  changeOptionals(
    newOptional: CartType['items'][0]['options'],
    catId: number
  ): void;
  allAccess: boolean;
  scrollToNextItem(indexNext: number): void;
  index: number;
  handleOpenModal: ({
    src,
    title,
    price,
  }: {
    src: string;
    title: string;
    price: string;
  }) => void;
};

export default function OptionalCheckbox({
  option,
  changeOptionals,
  allAccess,
  index,
  scrollToNextItem,
  handleOpenModal,
}: OptionalType) {
  const [ids, setIds] = useState<number[]>([]);
  const [items, setItems] = useState<CartType['items'][0]['options']>([]);
  const { openProduct } = usePages();
  const { changeApiLanguage } = useTranslation();

  useEffect(() => {
    if (!openProduct) {
      setItems([]);
      setIds([]);
    }
  }, [openProduct]);

  function changeOptional(
    item: ListProductsResponse['conteudo'][0]['opcionais'][0]['registros'][0]
  ) {
    const newArrIds = [...ids];
    const newArrItems = [...items];

    if (newArrIds.includes(item.id)) {
      newArrIds.splice(newArrIds.indexOf(item.id), 1);
      newArrItems.forEach((currentItem, index) => {
        if (currentItem.id === item.id) {
          newArrItems.splice(index, 1);
        }
      });
    } else if (
      newArrIds.filter((v) => v).length !== option.qtde_max_selecao ||
      option.qtde_max_selecao === 0
    ) {
      newArrIds.push(item.id);
      newArrItems.push({
        catId: option.id,
        id: item.id,
        qtd: 1,
        value: item.preco,
        name: item.descricao,
      });
    }

    if (
      option.qtde_max_selecao > 0 &&
      newArrIds.filter((v) => v).length === option.qtde_max_selecao
    ) {
      scrollToNextItem(++index);
    }

    setIds(newArrIds);
    setItems(newArrItems);
    changeOptionals(newArrItems, option.id);
  }

  return (
    <ul>
      {option.registros.map((listItem) => (
        <li key={listItem.id} className="optionalBodyItem">
          <div
            onClick={() => (allAccess ? changeOptional(listItem) : null)}
            style={{ flex: 1 }}
          >
            <p className="optionalBodyDesc">{changeApiLanguage('descricao', listItem)}</p>
            {listItem.detalhes && (
              <span className="optionalBodyDetail">{changeApiLanguage('detalhes', listItem)}</span>
            )}
            {listItem.preco > 0 && (
              <span className="optionalBodyPrice">
                + R$ {CommonMask.currency(listItem.preco.toFixed(2).toString())}
              </span>
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {listItem.tipo === 'P' && (
              <div
                onClick={() => {
                  handleOpenModal({
                    price: `+R$ ${CommonMask.currency(
                      listItem.preco.toFixed(2).toString()
                    )}`,
                    src: listItem.imagem,
                    title: listItem.descricao,
                  });
                }}
                className="optionalThumb"
                style={{ backgroundImage: `url(${listItem.imagem})` }}
              ></div>
            )}
            {allAccess && (
              <label
                className="checkboxContainer"
                onClick={() => (allAccess ? changeOptional(listItem) : null)}
              >
                <input
                  type="checkbox"
                  checked={ids.includes(listItem.id)}
                  disabled
                />
                <span className="checkboxCheckmark"></span>
              </label>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
}
