import { createSlice } from '@reduxjs/toolkit';
import { GetClient, GetStores } from '@/services/delivery/auth';
import { SearchClienteResponse } from '@/models/delivery/client.response';
import { StoresResponse } from '@/models/delivery/stores.response';

const initialState = {
  client: <SearchClienteResponse | null>null,
  listStores: <StoresResponse | null>null,
  loading: false,
  loadingStores: false,
  requestError: <null | string>null,
};

const authDeliverySlice = createSlice({
  name: 'authDelivery',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetClient.pending, (state) => {
        state.requestError = null;
        state.client = null;
        state.loading = true;
      })
      .addCase(GetClient.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.client = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(GetStores.pending, (state) => {
        state.requestError = null;
        state.listStores = null;
        state.loadingStores = true;
      })
      .addCase(GetStores.fulfilled, (state, { payload }) => {
        state.loadingStores = false;
        if (payload) {
          if (payload.retorno) {
            state.listStores = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      });
  },
});

export const { reset } = authDeliverySlice.actions;
export default authDeliverySlice.reducer;
