import axios from 'axios';

const api = axios.create({
  baseURL: process.env.API_URL + 'comanda',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'API-TOKEN': process.env.API_TOKEN,
  },
});

export default api;
