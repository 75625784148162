import axios from 'axios';

const api = axios.create({
  baseURL: process.env.API_URL + 'fidelidade',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'API-TOKEN': process.env.API_TOKEN, //'$2y$10$g0wJQ3juHUL5Vx4TA1qnBullNhrEn6C67JBeWo.8.1LirmQaqdU1K',
  },
});

export default api;
