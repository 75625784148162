import { createSlice } from '@reduxjs/toolkit';
import { DoLoginFidelity } from '@/services/fidelity/auth';
import UserStorage from '@/storages/user-storage';
import router from 'next/router';
import { DoRegisterFidelity } from '@/services/fidelity/register';
import { PunctuationFidelityResponse } from '@/models/punctuationFidelity.response';
import { PunctuationFidelity } from '@/services/fidelity/punctuation';

const initialState = {
	loading: true,
	response: <PunctuationFidelityResponse | null>null,
	requestError: <null | string>null,
};

const punctuationFidelitySlice = createSlice({
	name: 'punctuationFidelity',
	initialState,
	reducers: {
		reset: () => initialState,
		startFidelityLoading: (state, action) => {
			state.loading = true;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(PunctuationFidelity.pending, state => {
				state.requestError = null;
				state.response = null;
			})
			.addCase(PunctuationFidelity.fulfilled, (state, { payload }) => {
				if (payload) {
					if (payload.retorno) {
						state.response = payload.conteudo;
						state.loading = false;
					} else {
						state.requestError = payload.mensagem;
					}
				}
			});
	},
});

export const { reset, startFidelityLoading } = punctuationFidelitySlice.actions;
export default punctuationFidelitySlice.reducer;
