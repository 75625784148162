import { EventsCardModel } from '@/models/eventsCardModel';
import { EventsClientModelResponse } from '@/models/eventsClientModel.response';
import { EventEventModel, EventModelResponse, EventsCartModel } from '@/models/eventsEventModel.response';
import { EventsLoginModel } from '@/models/eventsLoginModel';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	clientData: {} as EventsClientModelResponse,
	eventsData: {} as EventModelResponse,
	loginPageStatus: false,
	loading: false,
	cart: [] as EventsCartModel,
	eventsLogin: {} as EventsLoginModel,
	cardInfo: { cardPrintName: '', cardNumber: '', cardMonth: 'JAN', cardYear: '2024', cardName: '', cardCVV: '', cardEmail: '', cardCPF: '', cardInstallments: '01', cardTelefone: '' } as EventsCardModel,
	requestError: <null | string>null,
	openPaymentPage: false,
	event: {} as EventEventModel,
	payOption: 2,
	cupomCode: '',
	totalVenda: '',
	modalInfo: { title: '', text: '', type: '', modalOpen: false, qrCode: '', buttonFunction: { btnLabel: '', onBtnClick: null as Function | null }, closeOntime: false },
	modalWarning: { btn: false, text: '', modalOpen: false },
	totalWithDiscount: 0,
};

const eventsSlice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		reset: () => initialState,
		changeEventsData(state, action) {
			state.eventsData = action.payload;
		},
		changeClientData(state, action) {
			state.clientData = action.payload;
		},
		changeLoginDataStatus(state, action) {
			state.loginPageStatus = !state.loginPageStatus;
		},
		changeCart(state, action) {
			state.cart = action.payload;
		},
		changeEventsLogin(state, action) {
			state.eventsLogin = action.payload;
		},
		changeCardInfo(state, action) {
			state.cardInfo = action.payload;
		},
		setOpenPaymentPage(state, action) {
			state.openPaymentPage = action.payload;
		},
		changeActiveEvent(state, action) {
			state.event = action.payload;
		},
		changePayOption(state, action) {
			state.payOption = action.payload;
		},
		changeCupomCode(state, action) {
			state.cupomCode = action.payload;
		},
		changeTotalVenda(state, action) {
			state.totalVenda = action.payload;
		},
		changeModalInfo(state, action) {
			state.modalInfo = action.payload;
		},
		changeModalWarning(state, action) {
			state.modalWarning = action.payload;
		},
		resetEventsDetails(state) {
			state.cupomCode = '';
			state.payOption = 2;
			state.event = {} as EventEventModel;
			state.openPaymentPage = false;
			(state.cardInfo = { cardPrintName: '', cardNumber: '', cardMonth: 'JAN', cardYear: '2024', cardName: '', cardCVV: '', cardEmail: '', cardCPF: '', cardInstallments: '01', cardTelefone: '' } as EventsCardModel),
				(state.cart = [] as EventsCartModel);
			state.modalInfo = { title: '', text: '', type: '', modalOpen: false, qrCode: '', buttonFunction: { btnLabel: '', onBtnClick: null as Function | null }, closeOntime: false };
			state.modalWarning = { btn: false, text: '', modalOpen: false };
		},
		changeTotalWithDiscount(state, action) {
			state.totalWithDiscount = action.payload;
		},
	},
});

export const {
	changeTotalVenda,
	reset,
	changeCupomCode,
	changeModalInfo,
	changeModalWarning,
	resetEventsDetails,
	changeClientData,
	changePayOption,
	changeEventsData,
	changeLoginDataStatus,
	changeCart,
	setOpenPaymentPage,
	changeCardInfo,
	changeEventsLogin,
	changeActiveEvent,
	changeTotalWithDiscount,
} = eventsSlice.actions;
export default eventsSlice.reducer;
