import { createSlice } from '@reduxjs/toolkit';
import { DoLoginFidelity } from '@/services/fidelity/auth';
import UserStorage from '@/storages/user-storage';
import router from 'next/router';
import { FuncionariosFidelityResponse, RegisterFidelityResponse } from '@/models/registerFidelity.response';
import { ColaboratorsFidelity, DoRegisterFidelity } from '@/services/fidelity/register';

const initialState = {
  loading: false,
  loadingForm: false,
  data: <FuncionariosFidelityResponse | null>null,
  requestError: <null | string>null,
};

const colaboratorFidelitySlice = createSlice({
  name: 'colaboratorFidelity',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
    .addCase(ColaboratorsFidelity.pending, (state) => {
      state.requestError = null;
      state.data = null;
      state.loadingForm = true;
    })
    .addCase(ColaboratorsFidelity.fulfilled, (state, { payload }) => {
      
      if (payload) {
        if (payload.retorno) {
          state.data = payload;
        } else {
          state.requestError = payload.mensagem;
          state.loadingForm = false;
        }
      }
    });
  },
});

export const { reset } = colaboratorFidelitySlice.actions;
export default colaboratorFidelitySlice.reducer;
