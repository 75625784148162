import { usePages } from '@/contexts/pages';
import CommonMask from '@/helpers/masks';
import useTranslation from '@/hooks/cardapioUseTranslation';
import { CartType } from '@/models/cartModel';
import { ListProductsResponse } from '@/models/listProducts.response';
import React, { useEffect, useState } from 'react';

type OptionalType = {
	option: ListProductsResponse['conteudo'][0]['opcionais'][0];
	changeOptionals(newOptional: CartType['items'][0]['options'][0], catId: number, maxQtd: number): void;
	allAccess: boolean;
	scrollToNextItem(indexNext: number): void;
	index: number;
	handleOpenModal: ({ src, title, price }: { src: string; title: string; price: string }) => void;
};

export default function OptionalRadio({ option, changeOptionals, allAccess, index, scrollToNextItem, handleOpenModal }: OptionalType) {
	const [value, setValue] = useState(0);
	const { openProduct } = usePages();
	const { changeApiLanguage } = useTranslation();

	useEffect(() => {
		if (!openProduct) {
			setValue(0);
		}
	}, [openProduct]);

	function changeOptional(item: ListProductsResponse['conteudo'][0]['opcionais'][0]['registros'][0]) {
		setValue(item.id);
		changeOptionals(
			{
				catId: option.id,
				id: item.id,
				qtd: 1,
				value: item.preco,
				name: item.descricao,
			},
			option.id,
			option.qtde_max_selecao,
		);
		scrollToNextItem(++index);
	}

	return (
		<ul>
			{option.registros.map(listItem => (
				<li key={listItem.id} className="optionalBodyItem">
					<div onClick={() => (allAccess ? changeOptional(listItem) : null)} style={{ flex: 1 }}>
						<p className="optionalBodyDesc">{changeApiLanguage('descricao', listItem)}</p>
						{listItem.detalhes && <span className="optionalBodyDetail">{changeApiLanguage('detalhes', listItem)}</span>}
						{listItem.preco > 0 && <span className="optionalBodyPrice">+ R$ {CommonMask.currency(listItem.preco.toFixed(2).toString())}</span>}
					</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{listItem.tipo === 'P' && (
							<div
								onClick={() => {
									handleOpenModal({
										price: `+R$ ${CommonMask.currency(listItem.preco.toFixed(2).toString())}`,
										src: listItem.imagem,
										title: listItem.descricao,
									});
								}}
								className="optionalThumb"
								style={{ backgroundImage: `url(${listItem.imagem})` }}></div>
						)}

						{allAccess && (
							<label className="radioContainer" onClick={() => (allAccess ? changeOptional(listItem) : null)}>
								<input type="radio" checked={listItem.id === value} name={listItem.id.toString()} value={listItem.id} disabled />
								<span className="radioCheckmark"></span>
							</label>
						)}
					</div>
				</li>
			))}
		</ul>
	);
}
