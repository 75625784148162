import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { MakeOrderRequest } from '@/models/delivery/makeOrder.request';
import { MakeOrderResponse } from '@/models/delivery/makeOrder.response';
import { GetOrderResponse } from '@/models/delivery/getOrder.response';
import { GetOrderRequest } from '@/models/delivery/getOrder.request';
import { StatusOrderRequest } from '@/models/delivery/statusOrder.request';
import { StatusOrderResponse } from '@/models/delivery/statusOrder.response';
import { ListSchedulesResponse } from '@/models/delivery/listSchedules.response';
import { ListOrderResponse } from '@/models/delivery/listOrder.response';

export const PostOrder = createAsyncThunk('order/PostOrder', async (request: MakeOrderRequest) => {
	const response = await api
		.post(`/insere-pedido-entrega`, request)
		.then((r): MakeOrderResponse => r.data)
		.catch((error) => {
			return error.response.data;
		});
	return response;
});

export const StatusOrder = createAsyncThunk('order/StatusOrder', async (request: StatusOrderRequest) => {
	let url;
	if (request.phone) {
		url = `/consultar-status/${request.phone}`;
	} else {
		url = `/consultar-status/${request.cpf}`;
	}
	
	const response = await api
		.get(url)
		.then((r): StatusOrderResponse => {
			return r.data;
		})
		.catch((error) => {
			return error.response.data;
		});
	return response;
});

export const GetOrder = createAsyncThunk('order/GetOrder', async (request: GetOrderRequest) => {
	const url = `/consultar-status/${request.pedido}`;
	const response = await api
		.get(url)
		.then((r): GetOrderResponse => {
			return r.data;
		})
		.catch((error) => {
			return error.response.data;
		});
	return response;
});

export const ListSchedules = createAsyncThunk('order/ListSchedules', async () => {
	const response = await api
		.get(`/lista-horarios-agendamento`)
		.then((r): ListSchedulesResponse => {
			return r.data;
		})
		.catch((error) => {
			return error.response.data;
		});
	return response;
});

export const ListOrder = createAsyncThunk('order/ListOrder', async (value: string) => {
	const url = `/lista-pedido/${value}`;
	const response = await api
		.get(url)
		.then((r): ListOrderResponse => r.data)
		.catch((error) => {
			return error.response.data;
		});
	return response;
});
