import { CartType } from '@/models/cartModel';

const DeliveryStorage = {
  SetCart(cart: CartType, alias: string) {
    localStorage.setItem(
      '@ZeroFila:cartDelivery' + alias,
      JSON.stringify(cart)
    );
  },

  GetCart(alias: string): CartType | null {
    const data = localStorage.getItem('@ZeroFila:cartDelivery' + alias);

    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  },

  SetTimePayment(time: string, alias: string) {
    localStorage.setItem('@ZeroFila:timePaymentDelivery' + alias, time);
  },

  GetTimePayment(alias: string) {
    const data = localStorage.getItem('@ZeroFila:timePaymentDelivery' + alias);
    if (data) {
      return +data;
    } else {
      return null;
    }
  },

  RemoveTimePayment(alias: string) {
    localStorage.removeItem('@ZeroFila:timePaymentDelivery' + alias);
  },

  SetPix(data: { pixKey: string; transitionId: number }, alias: string) {
    localStorage.setItem('@ZeroFila:pixDelivery' + alias, JSON.stringify(data));
  },

  GetPix(alias: string): { pixKey: string; transitionId: number } | null {
    const data = localStorage.getItem('@ZeroFila:pixDelivery' + alias);
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  },

  RemovePix(alias: string) {
    localStorage.removeItem('@ZeroFila:pixDelivery' + alias);
  },
};

export default DeliveryStorage;
