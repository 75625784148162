import { CloseRounded } from '@mui/icons-material';
import React from 'react';

type ModalType = {
  open: boolean;
  img: {
    src: string;
    title: string;
    price: string;
  };
  handleClose(): void;
};

export default function ModalThumb({ open, handleClose, img }: ModalType) {
  if (!open) {
    return <></>;
  }
  return (
    <div id="modalThumb">
      <div className="modalContent">
        <div
          className="modalOverlay"
          onClick={(event) => {
            event.preventDefault();
            handleClose();
          }}
        ></div>
        <button
          onClick={handleClose}
          style={{
            backgroundColor: 'rgba(0, 0,0, 0.4)',
            height: 50,
            width: 45,
            marginLeft: 'auto',
            marginRight: 8,
            marginTop: 8,
            position: 'relative',
            zIndex: 10,
            color: '#fff',
          }}
        >
          <CloseRounded style={{ fontSize: 32, color: '#fff' }} />
        </button>
        <div className="modalBody">
          <img src={img.src} alt="" />
        </div>
        <div className="modalFooter">
          <p className="modalFooterTitle">{img.title}</p>
          <p>{img.price}</p>
        </div>
      </div>
    </div>
  );
}
