import { Poppins } from 'next/font/google';

export const poppins300 = Poppins({ subsets: ['latin'], weight: '300' });
export const poppins400 = Poppins({ subsets: ['latin'], weight: '400' });
export const poppins500 = Poppins({ subsets: ['latin'], weight: '500' });
export const poppins600 = Poppins({ subsets: ['latin'], weight: '600' });
export const poppins700 = Poppins({ subsets: ['latin'], weight: '700' });
export const poppins800 = Poppins({ subsets: ['latin'], weight: '800' });
export const poppins900 = Poppins({ subsets: ['latin'], weight: '900' });


export const p300 = poppins300.style.fontFamily + '!important';
export const p400 = poppins400.style.fontFamily + '!important';
export const p500 = poppins500.style.fontFamily + '!important';
export const p600 = poppins600.style.fontFamily + '!important';
export const p700 = poppins700.style.fontFamily + '!important';
export const p800 = poppins800.style.fontFamily + '!important';
export const p900 = poppins900.style.fontFamily + '!important';