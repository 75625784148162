import { createAsyncThunk } from '@reduxjs/toolkit';
import apiReservation from './apiReservation';
import { ClientResponse } from '@/models/reservation/client.response';
import { ClientRequest } from '@/models/reservation/client.request';
import { InfosRequest } from '@/models/reservation/infos.request';
import { InfosResponse } from '@/models/reservation/infos.response';
import { ReservationResponse } from '@/models/reservation/reservation.response';

export const GetClientReservation = createAsyncThunk(
  'reservation/GetClientReservation',
  async (request: ClientRequest) => {
    const response = await apiReservation
      .post(`/busca-cliente`, request)
      .then((r): ClientResponse => {
        apiReservation.defaults.headers['API-TOKEN'] = `${r.data.token}`;
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const GetReservationInfos = createAsyncThunk(
  'reservation/GetReservationInfos',
  async (request: InfosRequest) => {
    const response = await apiReservation
      .post(`/infos`, request)
      .then((r): InfosResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const FinishReservation = createAsyncThunk(
  'reservation/FinishReservation',
  async (request: any) => {
    const response = await apiReservation
      .post(`/efetuar-reserva`, request)
      .then((r): ReservationResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
