import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ShoppingCartOutlined } from '@mui/icons-material';
import CommonMask from '@/helpers/masks';
import { CartType } from '@/models/cartModel';
import { useCart } from '@/contexts/cart';
import { ListProductsResponse } from '@/models/listProducts.response';
import ModalInfo from '@/components/ModalInfo';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { createAlert, reset } from '@/redux/reducers/alert';
import { useRouter } from 'next/router';

type FooterType = {
	totValue: number;
	productQtd: number;
	setProductQtd: React.Dispatch<React.SetStateAction<number>>;
	onDismiss(): void;
	setOpenCart: React.Dispatch<React.SetStateAction<boolean>>;
	unityValue: number;
	idUnity: number;
	fieldObs: string;
	variableName: string;
	options: CartType['items'][0]['options'];
	product: ListProductsResponse['conteudo'][0] | null;
	integrationCode: string;
};

export default function FooterComp({ totValue, productQtd, setProductQtd, onDismiss, integrationCode, setOpenCart, idUnity, unityValue, options, variableName, product, fieldObs }: FooterType) {
	const { Add } = useCart();
	const dispatch = useAppDispatch();
	const [isDisabled, setIsDisabled] = useState(false);
	const [requiredOptions, setRequiredOptions] = useState<any>([]);
	const [showSnack, setShowSnack] = useState(false);
	const { list } = useAppSelector(state => state.home);
	const router = useRouter();
	const { departmentId } = router.query;
	const [snackMsg, setSnackMsg] = useState('');
	const { client } = useAppSelector(state => state.auth);

	function handleModal(state: boolean) {
		setShowSnack(state);
	}

	useEffect(() => {
		const newRequiredArr: any = [];
		product?.opcionais.forEach(optional => {
			if (optional.selecao_obg) {
				newRequiredArr.push({
					id: optional.id,
					min: optional.qtde_min_selecao,
				});
				setIsDisabled(true);
			}
		});

		setRequiredOptions(newRequiredArr);
	}, []);

	useEffect(() => {
		handleModal(false);

		if (options) {
			let anyRequired = false;
			requiredOptions.forEach((required: any) => {
				let qtd = 0;
				options.forEach(option => {
					if (option.catId === required.id) {
						qtd = qtd + option.qtd;
					}
				});

				if (qtd >= required.min) {
					if (anyRequired === false) {
						setIsDisabled(false);
					}
				} else {
					anyRequired = true;
					setIsDisabled(true);
				}
			});
		}
	}, [options]);

	function handleCart() {
		if (isDisabled) {
			setSnackMsg('Você precisa selecionar todos os opcionais obrigatórios!');
			setShowSnack(true);
		} else {
			handleModal(false);
			if (product) {
				const newCart: CartType['items'][0] = {
					id: product.id,
					img: product.imagens[0].imagem,
					unityValue,
					idUnity,
					qtd: productQtd,
					title: product.nome,
					qtde_add: product.qtde_add,
					qtde_minima: product.qtde_minima,
					maxQtd: product.qtde_max_pedido,
					options,
					variable: variableName,
					observation: fieldObs,
					produtos_relacionados: product.produtos_relacionados,
					integrationCode: integrationCode,
				};
				Add(newCart);
				onDismiss();
				{
					!client?.config_cliente.usa_ticket && setOpenCart(true);
				}
				{
					client?.config_cliente.usa_ticket &&
						dispatch(createAlert('Produto adicionado ao carrinho!')) &&
						setTimeout(() => {
							dispatch(reset());
						}, 3000);
				}
			}
		}
	}

	const isOpen = useMemo(() => {
		return (
			departmentId &&
			list?.conteudo?.filter(content => {
				return content.id === +departmentId;
			})[0].aberta
		);
	}, [departmentId]);

	return (
		<footer className="footer">
			{(isOpen || list?.tipo != 'M') && (
					<>
						<div className="footerBtn">
							<Button variant="text" className="footerQtdBtn" onClick={() => (productQtd > 1 ? setProductQtd(productQtd - 1) : null)}>
								<RemoveIcon />
							</Button>
							<span className="footerQtdTxt">{productQtd}</span>
							<Button variant="text" className="footerQtdBtn" onClick={() => setProductQtd(productQtd + 1)}>
								<AddIcon />
							</Button>
						</div>
						<Button variant="contained" className="footerBtn flex-1" onClick={handleCart}>
							<ShoppingCartOutlined />
							<span className="footerValue">R$ {CommonMask.currency(totValue.toFixed(2).toString())}</span>
						</Button>
					</>
				)}
			{!isOpen && list?.tipo === 'M' && (
				<Button variant="contained" className=" flex-1" disabled>
					<span className="footerValue">Apenas consulta</span>
				</Button>
			)}
			<ModalInfo open={showSnack} handler={handleModal} message={snackMsg} />
		</footer>
	);
}
