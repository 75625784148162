import React from 'react';

export default function CartIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.02157 19.1823C8.49975 19.1823 8.88739 18.7947 8.88739 18.3165C8.88739 17.8383 8.49975 17.4507 8.02157 17.4507C7.5434 17.4507 7.15576 17.8383 7.15576 18.3165C7.15576 18.7947 7.5434 19.1823 8.02157 19.1823Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5457 19.1823C18.0239 19.1823 18.4116 18.7947 18.4116 18.3165C18.4116 17.8383 18.0239 17.4507 17.5457 17.4507C17.0676 17.4507 16.6799 17.8383 16.6799 18.3165C16.6799 18.7947 17.0676 19.1823 17.5457 19.1823Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.09521 1H4.55847L6.87885 12.5932C6.95802 12.9919 7.17488 13.3499 7.49145 13.6048C7.80803 13.8596 8.20415 13.995 8.61048 13.9872H17.0262C17.4325 13.995 17.8286 13.8596 18.1452 13.6048C18.4618 13.3499 18.6786 12.9919 18.7578 12.5932L20.1431 5.32907H5.42428"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
