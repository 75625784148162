import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { Button, Fab, Typography } from '@mui/material';
import Link from 'next/link';
import { useAppSelector } from '@/redux/hooks';
import SearchPage from '@/layout/bottomSheets/search';
import CartPage from '@/layout/bottomSheets/cart';
import OrderPage from '@/layout/bottomSheets/order';
import OrderIcon from '@/assets/svg/Order';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import HomeIcon from '@/assets/svg/Home';
import LocationIcon from '@/assets/svg/Location';
import SearchIcon from '@/assets/svg/Search';
import CartIcon from '@/assets/svg/Cart';
import { useCart } from '@/contexts/cart';
import { useMain } from '@/contexts/main';
import MyItemsPage from '../bottomSheets/myItems';
import { usePages } from '@/contexts/pages';
import InformationsPage from '../bottomSheets/informations';
import { isMobile } from 'react-device-detect';
import PaymentPage from '../bottomSheets/payment';
import ResultSearchPage from '../bottomSheets/resultSearch';
import ProductPage from '../bottomSheets/product';
import ModalLogin from '@/components/ModalLogin';
import ModalPix from '@/components/ModalPix';
import SuccessPage from '../bottomSheets/successPage';
import { useRouter } from 'next/router';
import { poppins500, poppins600 } from '@/styles/typography/fonts';
import useTranslation from '@/hooks/cardapioUseTranslation';

export default function FixedBottomNavigation() {
	const { client, loginFields } = useAppSelector(state => state.auth);
	const { list } = useAppSelector(state => state.home);
	const [value, setValue] = useState(2);
	const ref = useRef<HTMLDivElement>(null);
	const { cart } = useCart();
	const { qrcode, storageLogin } = useMain();
	const { setOpenMyItems, setOpenInformation, setOpenOrder, setOpenSearch, setOpenCart } = usePages();
	const {changeApiLanguage, returnTranslation} = useTranslation();

	const router = useRouter();

	const allAccess = client?.config_cliente?.comanda_sem_qrcode ? true : client && !client.config_cliente.somente_cardapio ? (qrcode && loginFields?.retorno) || storageLogin : false;

	return (
		<section id="bottomNavigator">
			{((client && isMobile) || client?.config_cliente?.comanda_sem_qrcode) && (
				<Box sx={{ pb: 7 }} ref={ref}>
					<CssBaseline />
					<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} className="bg">
						{client?.config_cliente.usa_ticket && cart.tot ? (
							<Box
								sx={{
									color: 'white',
									backgroundColor: client ? '#' + client.config_cliente.cor : 'blue',
									minHeight: '5.5vh',
									maxHeight: '5.5vh',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '5vw',
									'@media (orientation: landscape)': {
										padding: '2vw !important',
										display:' flex',
										justifyContent: 'center',
									},
								}}>
								<Box
									sx={{
										display: 'flex',
										width: '100vw',
										justifyContent: 'space-between',
										alignItems: 'center',
										'@media (orientation: landscape)': {
											width: '700px !important',
										},
									}}>
									<Typography fontFamily={poppins500.style.fontFamily + '!important'} fontSize={'.75rem'}>
										<b>Total:</b> R$ {cart.tot.toFixed(2)}
									</Typography>
									<Button size="small" onClick={() => setOpenCart(true)} style={{ backgroundColor: 'white', color: client ? '#' + client.config_cliente.cor : 'blue', textTransform: 'none' }} sx={{ px: '1rem' }}>
										Carrinho
									</Button>
								</Box>
							</Box>
						) : (
							<></>
						)}
						<BottomNavigation
							showLabels
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
							}}
							className="list">
							{allAccess && !client?.config_cliente?.usa_ticket && (
								<BottomNavigationAction
									onClick={() => {
										(loginFields?.config_qrcode.pre_pago || client?.config_cliente?.comanda_sem_qrcode) ? setOpenMyItems(true) : setOpenOrder(true);
									}}
									label={returnTranslation('orderNav')}
									icon={<OrderIcon />}
									className="item"
								/>
							)}
							{client?.config_cliente?.usa_ticket && (
								<BottomNavigationAction
									onClick={() => {
										router.push('/tickets');
									}}
									label="Tickets"
									icon={<ConfirmationNumberOutlinedIcon />}
									className="item"
								/>
							)}
							{!client?.config_cliente?.usa_ticket && <BottomNavigationAction label={returnTranslation('search')} onClick={() => setOpenSearch(true)} icon={<SearchIcon />} className="item" />}
							<BottomNavigationAction
								LinkComponent={(props: any) => <Link href={client?.config_cliente?.usa_ticket ? '/busca' : list?.conteudo ? '/' : '/cardapio'} {...props} />}
								href={list?.conteudo ? '/' : '/cardapio'}
								style={{ top: -15 }}
								showLabel={false}
								className="roundedItem"
								icon={
									<Fab size="large" className="roundedItemBlock">
										<HomeIcon />
									</Fab>
								}
							/>
							{allAccess && (
								<BottomNavigationAction
									label={returnTranslation('cart')}
									onClick={() => setOpenCart(true)}
									icon={
										<div className="cartIcon">
											{cart && cart.items.length > 0 && <span className="cartLabel">{cart.items.length}</span>}
											<CartIcon />
										</div>
									}
									className="item"
								/>
							)}
							{!client?.config_cliente?.usa_ticket && <BottomNavigationAction label={returnTranslation('contact')} onClick={() => setOpenInformation(true)} icon={<LocationIcon />} className="item" />}
						</BottomNavigation>
					</Paper>
				</Box>
			)}
			<ProductPage />
			{isMobile && (
				<>
					<SearchPage />
					<ResultSearchPage />
					<CartPage />
					<InformationsPage />
					<PaymentPage />
					<ModalLogin />
					<ModalPix />
					<SuccessPage />

					{(loginFields?.config_qrcode.pre_pago || client?.config_cliente?.comanda_sem_qrcode) ? <MyItemsPage /> : <OrderPage />}
				</>
			)}
		</section>
	);
}
