import React from 'react';

export default function TrashIcon() {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.8 3H1.2V10.8C1.2 11.1183 1.32643 11.4235 1.55147 11.6485C1.77652 11.8736 2.08174 12 2.4 12H8.4C8.71826 12 9.02348 11.8736 9.24853 11.6485C9.47357 11.4235 9.6 11.1183 9.6 10.8V3H1.8ZM4.2 10.2H3V4.8H4.2V10.2ZM7.8 10.2H6.6V4.8H7.8V10.2ZM8.1708 1.2L7.2 0H3.6L2.6292 1.2H0V2.4H10.8V1.2H8.1708Z"
        fill="currentColor"
      />
    </svg>
  );
}
