import { ListTakeAwayResponse } from '@/models/delivery/listTakeAway.response';
import { MakePaymentResponse } from '@/models/delivery/makePayment.response';
import { ValidCouponResponse } from '@/models/delivery/validCoupon.response';
import { StatusTransitionResponse } from '@/models/statusTransition.response';
import { ListTakeAway, PostCoupon } from '@/services/delivery/payment';
import { GetStatusTransition, PostPayment } from '@/services/payment';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  responsePayment: <MakePaymentResponse | null>null,
  couponResponse: <ValidCouponResponse | null>null,
  status: <StatusTransitionResponse | null>null,
  listTakeAway: <ListTakeAwayResponse | null>null,
  loading: false,
  loadingStatus: false,
  requestError: <null | string>null,
};

const paymentDeliverySlice = createSlice({
  name: 'paymentDelivery',
  initialState,
  reducers: {
    reset: () => initialState,
    resetCoupon: (state) => {
      state.couponResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostPayment.pending, (state) => {
        state.requestError = null;
        state.responsePayment = null;
        state.loading = true;
      })
      .addCase(PostPayment.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.responsePayment = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(PostCoupon.pending, (state) => {
        state.requestError = null;
        state.couponResponse = null;
        state.loading = true;
      })
      .addCase(PostCoupon.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.sucesso) {
            state.couponResponse = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(GetStatusTransition.pending, (state) => {
        state.requestError = null;
        state.status = null;
        state.loadingStatus = true;
      })
      .addCase(GetStatusTransition.fulfilled, (state, { payload }) => {
        state.loadingStatus = false;
        if (payload) {
          if (payload.retorno) {
            state.status = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(ListTakeAway.pending, (state) => {
        state.requestError = null;
        state.listTakeAway = null;
      })
      .addCase(ListTakeAway.fulfilled, (state, { payload }) => {
        if (payload) {
          if (payload.retorno) {
            state.listTakeAway = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      });
  },
});

export const { reset, resetCoupon } = paymentDeliverySlice.actions;
export default paymentDeliverySlice.reducer;
