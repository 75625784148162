import React from 'react';

export default function OrderIcon() {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.13043 1.65216H3.21739C1.99276 1.65216 1 2.64492 1 3.86955V16.7826C1 18.0072 1.99276 19 3.21739 19H13.6522C14.8768 19 15.8696 18.0072 15.8696 16.7826V3.86955C15.8696 2.64492 14.8768 1.65216 13.6522 1.65216H12.1522"
        stroke="currentColor"
      />
      <rect
        x="4.80432"
        y="0.5"
        width="7.26086"
        height="2.30435"
        rx="0.5"
        stroke="currentColor"
      />
      <path d="M5.08185 16.2059H8.43479H11.7877" stroke="currentColor" />
    </svg>
  );
}
