import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { LoginFidelityResponse } from '@/models/loginFidelity.response';
import { SearchClienteResponse } from '@/models/client.response';

export const GetClient = createAsyncThunk(
  'authDelivery/GetClient',
  async (request: SearchClienteResponse) => {
    const response = await api
      .post(`/busca-cliente`, request)
      .then((r): SearchClienteResponse => {
        api.defaults.headers['API-TOKEN'] = `${r.data.token}`;
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);


export const DoLoginFidelity = createAsyncThunk(
  'auth/DoLoginFidelity',
  async (request: any) => {
    const response = await api
      .post('/login-usuario', request)
      .then((r): LoginFidelityResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
