import {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

type PagesContextType = {
  openMyItems: boolean;
  setOpenMyItems: Dispatch<SetStateAction<boolean>>;
  openOrder: boolean;
  setOpenOrder: Dispatch<SetStateAction<boolean>>;
  openCart: boolean;
  setOpenCart: Dispatch<SetStateAction<boolean>>;
  openInformation: boolean;
  setOpenInformation: Dispatch<SetStateAction<boolean>>;
  openPayment: boolean;
  setOpenPayment: Dispatch<SetStateAction<boolean>>;
  openProduct: boolean;
  setOpenProduct: Dispatch<SetStateAction<boolean>>;
  openSearch: boolean;
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
  openResultSearch: boolean;
  setOpenResultSearch: Dispatch<SetStateAction<boolean>>;
  openSuccessPage: boolean;
  setOpenSuccessPage: Dispatch<SetStateAction<boolean>>;
};

const PagesContext = createContext<PagesContextType>({} as PagesContextType);

export function PagesProvider({ children }: { children: ReactNode }) {
  const [openMyItems, setOpenMyItems] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openInformation, setOpenInformation] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openResultSearch, setOpenResultSearch] = useState(false);
  const [openSuccessPage, setOpenSuccessPage] = useState(false);

  return (
    <PagesContext.Provider
      value={{
        openMyItems,
        setOpenMyItems,
        openOrder,
        setOpenOrder,
        openCart,
        setOpenCart,
        openInformation,
        setOpenInformation,
        openPayment,
        setOpenPayment,
        openProduct,
        setOpenProduct,
        openSearch,
        setOpenSearch,
        openResultSearch,
        setOpenResultSearch,
        openSuccessPage,
        setOpenSuccessPage,
      }}
    >
      {children}
    </PagesContext.Provider>
  );
}

export function usePages() {
  const context = useContext(PagesContext);

  return context;
}
