import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { ListLinksResponse } from '@/models/listLinks.response';

export const ListLinks = createAsyncThunk('links/ListLinks', async () => {
  const response = await api
    .get(`/links`)
    .then((r): ListLinksResponse => r.data)
    .catch((error) => {
      return error.response.data;
    });
  return response;
});
