import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';

export default function SubtleConfirmationAlert() {
	const { open, text } = useAppSelector(state => state.alert);
    const dispatch = useAppDispatch();

    return (
		<Box sx={{ width: '100%', position: 'fixed', top: 0, zIndex: 99999999 }}>
			<Collapse in={open}>
				<Alert
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								dispatch({ type: 'alert/reset' })
							}}>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2 }}>
					{text}
				</Alert>
			</Collapse>
		</Box>
	);
}
