import { GetClient } from '@/services/auth';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { createContext, useEffect, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';
import { LoginResponse } from '@/models/login.response';
import UserStorage from '@/storages/user-storage';
import { ClientModel } from '@/models/clientModel';
import SessionStorage from '@/storages/session-storage';
import { ListGroups } from '@/services/home';
import { ListProductsResponse } from '@/models/listProducts.response';
import { GetBanners } from '@/services/menu';

type MainContextType = {
	storageLogin: LoginResponse | null;
	storageFidelityLogin: number | null;
	storageClientPre: ClientModel | null;
	storageOrderId: number | null;
	qrcode: string;
	setQrcode: Dispatch<SetStateAction<string>>;
	setCurrentProduct: React.Dispatch<React.SetStateAction<ListProductsResponse['conteudo'][0] | null>>;
	product: ListProductsResponse['conteudo'][0] | null;
	isStoreOpen: boolean;
	setIsStoreOpen: Dispatch<SetStateAction<boolean>>;
};

const MainContext = createContext<MainContextType>({} as MainContextType);

export function MainProvider({ children }: { children: ReactNode }) {
	const dispatch = useAppDispatch();
	const loginQrCodeId = useAppSelector(state => state.auth.loginFields?.config_qrcode.id);
	const [mainColor, setMainColor] = useState('#41a839');
	const { client } = useAppSelector(state => state.auth);
	const [qrcode, setQrcode] = useState('');
	// const [qrcode, setQrcode] = useState(
	//   '$2y$10$h2LP6cCluJ2bwvVqqcRPPutPbepQP7.4trW63xrGWeC1dG0xGxi.OP'
	// );
	const [isStoreOpen, setIsStoreOpen] = useState(true);
	const [product, setCurrentProduct] = useState<ListProductsResponse['conteudo'][0] | null>(null);

	const [storageLogin, setStorageLogin] = useState<MainContextType['storageLogin']>(null);
	const [storageFidelityLogin, setStorageFidelityLogin] = useState<MainContextType['storageFidelityLogin']>(null);
	const [storageClientPre, setStorageClientPre] = useState<MainContextType['storageClientPre']>(null);
	const [storageOrderId, setStorageOrderId] = useState<MainContextType['storageOrderId']>(null);

	useEffect(() => {
		const getStorageLogin = UserStorage.GetLogin();
		const newQrCode = qrcode ? qrcode : UserStorage.GetQrCode();
		if (newQrCode) {
			const getStorageClientPre = UserStorage.GetClientPre(newQrCode);
			const getOrderId = UserStorage.GetOrderId(newQrCode);

			setStorageLogin(getStorageLogin);
			setStorageClientPre(getStorageClientPre);
			setStorageOrderId(getOrderId);
		}
	}, [qrcode]);

	useEffect(() => {
		const host = window.location.href.replace('https://', '').replace('http://', '');
		// const alias = url.split('/')[2].split('.')[0].includes('cardapio-')
		// 	? url.split('/')[2].split('.')[0].replace('cardapio-', '')
		// 	: url.split('/')[2].split('.')[0].includes('cardapio')
		// 	? url.split('/')[2].split('.')[1]
		// 	: url.split('/')[2].split('.')[0];

		let alias;
		if (host.split('.')[0].includes('kds')) {
			alias = '';
		} else if (host.split('.')[0].includes('cardapio-')) {
			alias = host.split('.')[0].replace('cardapio-', '');
		} else if (host.split('.')[0].includes('cardapio')) {
			alias = host.split('.')[1];
		} else if (host.split('.')[0].includes('www')) {
			alias = host.split('.')[1];
		} else {
			alias = host.split('.')[0];
		}

		(async () => {
			const getQrCodeStorage = await SessionStorage.GetQrCode();

			if (getQrCodeStorage && !qrcode) {
				await UserStorage.SetQrcode(getQrCodeStorage);
				await setQrcode(getQrCodeStorage);
			}

			await dispatch(GetClient({ alias: alias }));
		})();
	}, []);

	useEffect(() => {
		if (client && client.config_cliente.cor) {
			setMainColor('#' + client.config_cliente.cor);
		}

		if (client) {
			if (qrcode) {
				(async () => {
					await dispatch(ListGroups({ qrcode_id: loginQrCodeId }));
				})();
			} else if (client.config_cliente.comanda_sem_qrcode) {
				(async () => {
					await dispatch(ListGroups({ qrcode_id: +'0' }));
					await dispatch(GetBanners());
				})();
			} else {
				(async () => {
					await dispatch(ListGroups({ qrcode_id: undefined }));
				})();
			}
		}
	}, [client]);

	return (
		<MainContext.Provider
			value={{
				storageLogin: storageLogin,
				storageFidelityLogin: storageFidelityLogin,
				storageClientPre: storageClientPre,
				storageOrderId: storageOrderId,
				qrcode: qrcode,
				setQrcode: setQrcode,
				product: product,
				setCurrentProduct: setCurrentProduct,
				isStoreOpen: isStoreOpen,
				setIsStoreOpen: setIsStoreOpen,
			}}>
			<style jsx global>{`
				:root {
					--main-color: ${mainColor};
				}
			`}</style>
			{children}
		</MainContext.Provider>
	);
}

export function useMain() {
	const context = useContext(MainContext);

	return context;
}
