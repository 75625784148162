import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { StatusTransitionResponse } from '@/models/statusTransition.response';
import { MakePaymentRequest } from '@/models/delivery/makePayment.request';
import { ValidCouponRequest } from '@/models/delivery/validCoupon.request';
import { ValidCouponResponse } from '@/models/delivery/validCoupon.response';
import { ListTakeAwayResponse } from '@/models/delivery/listTakeAway.response';
import { MakePaymentResponse } from '@/models/delivery/makePayment.response';

export const PostPayment = createAsyncThunk(
  'payment/PostPayment',
  async (request: MakePaymentRequest) => {
    const response = await api
      .post(`/insere-pedido-entrega`, request)
      .then((r): MakePaymentResponse => r.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const PostCoupon = createAsyncThunk(
  'payment/PostCoupon',
  async (request: ValidCouponRequest) => {
    const response = await api
      .post(`/validar-cupom`, request)
      .then((r): ValidCouponResponse => r.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const ListTakeAway = createAsyncThunk(
  'payment/ListTakeAway',
  async () => {
    const response = await api
      .get(`/lista-locais-retirada`)
      .then((r): ListTakeAwayResponse => r.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const GetStatusTransition = createAsyncThunk(
  'payment/GetStatusTransition',
  async (codeTrasition: number) => {
    const url = `/consultar-transacao/${codeTrasition}`;
    const response = await api
      .get(url)
      .then((r): StatusTransitionResponse => r.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
