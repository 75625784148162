import React from 'react';

export default function CardIcon() {
  return (
    <svg
      width="37"
      height="28"
      viewBox="0 0 37 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_237_1002)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.50023 4H27.5072C28.9077 4 30.0086 5.09912 30.0086 6.49806V7.49738C30.0086 7.79721 29.8083 7.99681 29.5081 7.99681H6.49994C6.19975 7.99681 6 7.79721 6 7.49738V6.49806C6 5.09912 7.10029 4 8.50023 4ZM6.49994 9.99499H29.5081C29.8083 9.99499 30.0086 10.195 30.0086 10.4949V17.4887C30.0086 18.8877 28.9077 19.9868 27.5072 19.9868H8.50023C7.10029 19.9868 6 18.8877 6 17.4887V10.4949C6 10.195 6.19975 9.99499 6.49994 9.99499ZM13.5023 13.9918H9.50064C9.20102 13.9918 9.0007 14.1918 9.0007 14.4912C9.0007 14.7911 9.20102 14.9911 9.50064 14.9911H13.5023C13.8025 14.9911 14.0023 14.7911 14.0023 14.4917C14.0023 14.1918 13.8025 13.9918 13.5023 13.9918ZM9.50064 16.9893H16.5031C16.8038 16.9893 17.0036 16.7897 17.0036 16.4898C17.0036 16.19 16.8038 15.9904 16.5031 15.9904H9.50064C9.20102 15.9904 9.0007 16.19 9.0007 16.4898C9.0007 16.7897 9.20102 16.9893 9.50064 16.9893ZM24.0065 16.6894C24.3062 16.8895 24.6064 16.9893 25.0064 16.9893C26.1067 16.9893 27.0073 16.0902 27.0073 14.9911C27.0073 13.892 26.1067 12.9925 25.0064 12.9925C24.6064 12.9925 24.3062 13.0923 24.0065 13.2923C23.7058 13.0923 23.4062 12.9925 23.0061 12.9925C21.9053 12.9925 21.0047 13.892 21.0047 14.9911C21.0047 16.0902 21.9053 16.9893 23.0061 16.9893C23.4062 16.9893 23.7058 16.8895 24.0065 16.6894Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_237_1002"
          x="0"
          y="0"
          width="36.0085"
          height="27.9868"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.101961 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_237_1002"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_237_1002"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
