import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { PunctuationFidelityResponse } from '@/models/punctuationFidelity.response';

export const PunctuationFidelity = createAsyncThunk(
  'auth/PunctuationFidelity',
  async (userId: number) => {
    const response = await api
      .get('/informacoes/'+userId)
      .then((r): PunctuationFidelityResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
