import { useCart } from '@/contexts/cart';
import { usePages } from '@/contexts/pages';
import useTranslation from '@/hooks/cardapioUseTranslation';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { resetListOrder } from '@/redux/reducers/order';
import { reset } from '@/redux/reducers/payment';
import { Button, Modal } from '@mui/material';
import { useRouter } from 'next/router';
import React, { use, useEffect } from 'react';

type CompType = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ModalSuccess({ open, setOpen }: CompType) {
	const dispatch = useAppDispatch();
	const router = useRouter();
	const { loginFields, client } = useAppSelector(state => state.auth);
	const { setOpenMyItems, setOpenSuccessPage, setOpenPayment } = usePages();
	const { clearCart } = useCart();
	const { changeApiLanguage, returnTranslation } = useTranslation();

	useEffect(() => {
		if (open) {
			clearCart();
		}
	}, [open]);

	return (
		<Modal open={open} id="modalPix" disablePortal disableEnforceFocus disableAutoFocus disableScrollLock>
			<div className="pixContent">
				<div>
					{client?.config_cliente.titulo_pagto_aut_prepago ? <p className="statusTitle">{changeApiLanguage('titulo_pagto_aut_prepago', client.config_cliente)}</p> : <p className="statusTitle">{returnTranslation('allSet')} </p>}
					<br />

					{client?.config_cliente.msg_pagto_aut_prepago ? (
						<p className="pixTitle">{changeApiLanguage('msg_pagto_aut_prepago', client.config_cliente)}</p>
					) : (
						<>
							<p className="pixTitle">{returnTranslation('paymentSuccessfully')} </p>
							<p className="pixDesc text-center">{returnTranslation('thankPreference')} </p>
						</>
					)}
					{!client?.config_cliente.msg_pagto_aut_prepago ? (
						<p className="pixTitle">{returnTranslation('purchaseCompleted')} </p>
					) : (
						<>
							<p className="pixTitle">{returnTranslation('paymentSuccessfully')} </p>
							<p className="pixDesc text-center">{returnTranslation('thankPreference')} </p>
						</>
					)}
					<br />
					<Button
						variant="contained"
						className="btnPix"
						onClick={() => {
							clearCart();
							dispatch(resetListOrder());
							
							setOpen(false);
							if (client?.config_cliente?.comanda_sem_qrcode && client?.config_cliente?.usa_ticket) {
								setOpenPayment(false);
								router.push('/tickets');
							}
							if (loginFields || (client?.config_cliente?.comanda_sem_qrcode && !client?.config_cliente?.usa_ticket)) {
								if ((client?.config_cliente?.comanda_sem_qrcode && !client?.config_cliente?.usa_ticket) || loginFields?.config_qrcode.pre_pago ) {
									setOpenMyItems(true);
								} else {
									localStorage.clear();
									sessionStorage.clear();
									setOpenSuccessPage(true);
									dispatch(reset());
								}
							} else {
								dispatch(reset());
							}
						}}>
						{client?.config_cliente?.comanda_sem_qrcode && client?.config_cliente?.usa_ticket ? returnTranslation('viewTickets') : 'Ok'}
					</Button>
				</div>
			</div>
		</Modal>
	);
}
