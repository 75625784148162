import ModalInfo from '@/components/ModalInfo';
import useTranslation from '@/hooks/cardapioUseTranslation';
import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';

type CompType = {
  time: string;
  pixKey: string;
};

export default function ContentComp({ time, pixKey }: CompType) {
  const [showSnack, setShowSnack] = useState(false);
  const { returnTranslation } = useTranslation();

  function onCopy() {
    navigator.clipboard.writeText(pixKey || '');
    setShowSnack(true);
  }

  function handleAlert(state: boolean) {
    setShowSnack(state);
  }

  return (
    <div>
     <p className="pixTitle"> {returnTranslation('codeGenerated')} </p>
			<p className="pixDesc">
				{returnTranslation('copyPix')} <span>{returnTranslation('copyCode')} </span>
			</p>
			<br />
			<p className="pixDesc">
				{returnTranslation('openBank')} <span>{returnTranslation('copyAndPaste')} </span>
			</p>
			<br />
			<p className="pixDesc">{returnTranslation('pasteCode')}</p>
			<br />
			<div className="timeBlock">
				<p className="timeTitle">{returnTranslation('timeout')}:</p>
				<p className="time">{time}</p>
      </div>
      <TextField
        value={pixKey}
        fullWidth
        className="blockKey"
        label="Código pix"
        variant="outlined"
        disabled={true}
        onChange={() => {
          null;
        }}
      />
      <Button variant="contained" className="btnPix" onClick={onCopy}>
      {returnTranslation('copyCodeFinish')}
      </Button>
      <ModalInfo open={showSnack} handler={handleAlert} message={returnTranslation('codeCopiedSuccessfully')} />     
    </div>
  );
}
