import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { IoCloseCircle } from 'react-icons/io5';
import { p500 } from '@/styles/typography/fonts';
import { usePages } from '@/contexts/pages';
import CommonMask from '@/helpers/masks';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { ListOrder } from '@/services/order';

export default function ModalCpf() {
	const { client } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
	const { setOpenMyItems } = usePages();
	const [cpf, setCpf] = useState<string>('');

	function onDismiss() {
		setOpenMyItems(false);
	}

	function handleCpfChange(e: React.ChangeEvent<HTMLInputElement>) {
		const formattedCpf = e.target.value.replace(/\D/g, '').slice(0, 11);
		setCpf(formattedCpf);
	}

	return (
		<Modal open={true}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '90%',
					bgcolor: 'white',
					boxShadow: 20,
					padding: 4,
					borderRadius: '0.5rem',
				}}>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1vh' }}>
					<IoCloseCircle size={24} style={{ color: 'gray' }} onClick={onDismiss} />
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
					<Typography
						sx={{
							mb: 2,
							textAlign: 'center',
							fontFamily: p500,
							fontSize: '1.2rem',
						}}>
						Digite o seu CPF e acesse seus pedidos:
					</Typography>
					<TextField
						sx={{
							input: { textAlign: 'center' },
						}}
						id="outlined-basic"
						placeholder="DIGITE SEU CPF"
						variant="outlined"
						fullWidth
						value={CommonMask.cpf(cpf)}
						onChange={handleCpfChange}
					/>

					<Button
						onClick={async() => {
							await dispatch(ListOrder(cpf));
						}}
						sx={{
							background: '#' + client?.config_cliente?.cor,
							marginTop: '2vh',
							color: 'white',
							height: '6vh',
							'&:hover': {
								backgroundColor: '#' + client?.config_cliente?.cor,
							},
						}}>
						Entrar
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}
