import Star from '@/assets/svg/Star';
import { useMain } from '@/contexts/main';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { PostRating } from '@/services/success';
import UserStorage from '@/storages/user-storage';
import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';

const arrStarts = [1, 2, 3, 4, 5];

export default function RatingComp({
  pageInformation,
}: {
  pageInformation?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { responseRating, loadingRating } = useAppSelector(
    (state) => state.success
  );
  const { responseLogin } = useAppSelector((state) => state.auth);
  const { storageLogin, qrcode, storageOrderId } = useMain();
  const [fieldObs, setFieldObs] = useState('');
  const getOrderId = storageOrderId || UserStorage.GetOrderId(qrcode);
  const personalData = responseLogin || storageLogin;
  const [star, setStar] = useState(0);

  async function onSendRating() {
    if (personalData || getOrderId) {
      await dispatch(
        PostRating({
          comanda_id: personalData ? personalData.comanda_id : getOrderId || 0,
          comentario: fieldObs,
          estrelas: star,
        })
      );
    }
  }

  if (personalData || getOrderId) {
    return (
      <div id="ratingComp">
        {responseRating?.retorno ? (
          <>
            <p className={`succesMsg ${pageInformation && 'small'}`}>
              Obrigado!
            </p>
            <p className={`succesMsg ${pageInformation && 'small'}`}>
              {responseRating.mensagem}
            </p>
          </>
        ) : (
          <>
            {pageInformation && <p className="title">Deixe sua avalição</p>}
            <ul className="listStars">
              {arrStarts.map((listStar) => (
                <li
                  className={`star ${listStar <= star && 'active'}`}
                  key={listStar}
                  onClick={() => {
                    if (listStar === star) {
                      setStar(0);
                    } else {
                      setStar(listStar);
                    }
                  }}
                >
                  <Star />
                </li>
              ))}
            </ul>
            <TextField
              fullWidth
              multiline
              className="optionalObs"
              minRows={3}
              value={fieldObs}
              variant="outlined"
              onChange={(event) =>
                event.currentTarget.value.length <= 140
                  ? setFieldObs(event.currentTarget.value)
                  : null
              }
            />
            <Button
              variant="contained"
              className="btnLink"
              onClick={onSendRating}
              disabled={loadingRating}
            >
              Enviar avaliação
            </Button>
          </>
        )}
      </div>
    );
  }

  return <></>;
}
