import { Button } from '@mui/material';
import React from 'react';

type CompType = {
  onConfirmError(): void;
};

export default function ErrorComp({ onConfirmError }: CompType) {
  return (
    <div>
      <p className="statusTitle">OPS...</p>
      <br />
      <p className="pixTitle">Pagamento não efetuado e/ou estornado!</p>
      <p className="pixDesc text-center">
        Por favor tente efetuar o pagamento novamente!
      </p>

      <br />
      <Button variant="contained" className="btnPix" onClick={onConfirmError}>
        Ok
      </Button>
    </div>
  );
}
