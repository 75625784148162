import { CircularProgress, Modal } from '@mui/material';
import React from 'react';

type CompType = {
  txt: string;
  open: boolean;
};

export default function ModalLoading({ open, txt }: CompType) {
  return (
    <Modal
      open={open}
      id="modalLoading"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      disableScrollLock
    >
      <div className="content">
        <CircularProgress className="loading" />
        <p className="pixTitle">{txt}</p>
      </div>
    </Modal>
  );
}
