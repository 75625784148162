import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import useTranslation from '@/hooks/cardapioUseTranslation';
import { useAppSelector } from '@/redux/hooks';
import { p400, p500, p600 } from '@/styles/typography/fonts';
import { Image } from '@mui/icons-material';
import { Box, Button, Dialog, Modal, Rating, Slide, TextField, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';

interface RatingScreenCardapioProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function RatingScreenCardapio({ open, setOpen }: RatingScreenCardapioProps) {
	const { client, responseLogin } = useAppSelector(state => state.auth);
	const [ratings, setRatings] = useState<{ [id: string]: number }>({});
	const [observacao, setObservacao] = useState('');
	const { returnTranslation, changeApiLanguage } = useTranslation();

	function onDismiss() {
		setOpen(false);
		setRatings({});
		setObservacao('');
	}

	const handleRatingChange = (id: string, value: number | null) => {
		if (value !== null) {
			setRatings(prev => ({ ...prev, [id]: value }));
		}
	};
	const [modalMessage, setModalMessage] = useState('');
	const handleSubmit = async () => {
		const modulos = client?.config_cliente?.avaliacoes?.modulos?.map(modulo => ({
			id: modulo.id,
			peso: ratings[modulo.id]?.toString() || '0',
		}));

		const payload = {
			modulos,
			nome: responseLogin?.cliente?.nome_completo || '',
			email: responseLogin?.cliente?.email || '',
			telefone: responseLogin?.cliente?.fone || '',
			observacao: observacao || '',
			cpf: responseLogin?.cliente?.cpf || '',
		};

		try {
			const response = await fetch('https://pipeor.com/api/comanda/envia-avaliacao', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'API-TOKEN': client?.token || '',
				},
				body: JSON.stringify(payload),
			});
			const result = await response.json();
			if (result.sucesso) {
				setModalMessage(result.mensagem);
			}
		} catch (error) {
			setModalMessage(returnTranslation('errorSbumittng'));
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onDismiss}
			TransitionComponent={Transition}
			disableScrollLock
			scroll="paper"
			fullScreen
			PaperProps={{
				sx: {
					'@media (orientation: landscape)': {
						width: '745px',
						maxWidth: '100%',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						margin: 'auto',
					},
				},
			}}>
			<Box sx={{ marginTop: '2vh' }}>
				<HeaderBottomSheet title="" onDismiss={onDismiss} />
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
				<img src={client?.config_cliente?.img_logo ?? ''} className="logoAvaliacao"></img>

				<Typography className="typoAvaliacao" sx={{ fontFamily: p600 }}>
					{changeApiLanguage('titulo', client?.config_cliente?.avaliacoes)}
				</Typography>
				<Box className="containerBoxRating">
					{client?.config_cliente?.avaliacoes?.modulos?.map(modulo => {
						return (
							<Box className="boxRating" key={modulo.id}>
								<Typography sx={{ fontFamily: p400 }}>{changeApiLanguage('nome', modulo)} </Typography>
								<Rating name="half-rating" value={ratings[modulo.id]} onChange={(event, newValue) => handleRatingChange(modulo.id, newValue)} />
							</Box>
						);
					})}
				</Box>
				<Typography sx={{ fontFamily: p400, marginTop: 2 }}>{returnTranslation('comment')} </Typography>
				<TextField variant="outlined" fullWidth multiline rows={4} placeholder={returnTranslation('commentOrsuggestion')} value={observacao} onChange={e => setObservacao(e.target.value)} sx={{ width: '90%' }} />
				<Button
					onClick={handleSubmit}
					className="btnAvaliacao"
					sx={{
						background: '#' + client?.config_cliente?.cor,
						'&:hover': {
							backgroundColor: '#' + client?.config_cliente?.cor,
						},
					}}>
					<Typography sx={{ fontFamily: p400, textTransform: 'capitalize', color: 'white' }}>{returnTranslation('btnSendReview')} </Typography>
				</Button>
			</Box>
			{modalMessage && (
				<Modal open>
					<div className="modalAvaliação">
						<p className="textModalavaliação">{modalMessage}</p>

						<button
							onClick={() => {
								setModalMessage('');
								onDismiss();
							}}
							className="btnAvaliação">
							{returnTranslation('btnClose')}
						</button>
					</div>
				</Modal>
			)}
		</Dialog>
	);
}
