import { createSlice } from '@reduxjs/toolkit';
import {
  FinishReservation,
  GetClientReservation,
  GetReservationInfos,
} from '@/services/reservation';
import { ClientResponse } from '@/models/reservation/client.response';
import { InfosResponse } from '@/models/reservation/infos.response';
import { ReservationResponse } from '@/models/reservation/reservation.response';

const initialState = {
  client: <ClientResponse | null>null,
  infos: <InfosResponse | null>null,
  reservationResponse: <ReservationResponse | null>null,
  loading: false,
  loadingInfos: false,
  loadingResponse: false,
  requestError: <null | string>null,
};

const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetClientReservation.pending, (state) => {
        state.requestError = null;
        state.client = null;
        state.loading = true;
      })
      .addCase(GetClientReservation.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          if (payload.retorno) {
            state.client = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(GetReservationInfos.pending, (state) => {
        state.requestError = null;
        state.loadingInfos = true;
      })
      .addCase(GetReservationInfos.fulfilled, (state, { payload }) => {
        state.loadingInfos = false;
        if (payload) {
          if (payload.retorno) {
            state.infos = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(FinishReservation.pending, (state) => {
        state.requestError = null;
        state.reservationResponse = null;
        state.loadingResponse = true;
      })
      .addCase(FinishReservation.fulfilled, (state, { payload }) => {
        state.loadingResponse = false;
        if (payload) {
          if (payload.retorno) {
            state.reservationResponse = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      });
  },
});

export const { reset } = reservationSlice.actions;
export default reservationSlice.reducer;
