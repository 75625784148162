import { ControleFilaAdicionarClienteReponse } from '@/models/controleFilaAdicionarClienteReponse.model';
import { ControleFilaAmbiente, ControleFilaMesa } from '@/models/controleFilaAmbientes.model';
import { ControleFilaListagemClientes } from '@/models/controleFilaListagemClienteReponse.model';
import { ControleFilaLoginResponseModel } from '@/models/controleFilaLoginReponse.model';
import { MesasAgrupadas } from '@/models/filaEsperaV2Messas.model';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	controleFilaPageType: 'clientes',
	controleFilaAwaitingClients: [] as ControleFilaListagemClientes[],
	controleFilaSelectedClient: null as ControleFilaListagemClientes | null,
	controleFilaOpenClientDrawer: false,
	controleFilaClientPreview: false,
	loginData: {} as ControleFilaLoginResponseModel,
	mesasAgrupadas: [] as MesasAgrupadas,
	checkedMesaLivre: true,
	checkedMesaOcupado: true,
	controleFilaPage: 0,
	selectedAmbiente: 0,
	controleFilaAmbientes: [] as ControleFilaAmbiente[],
	controleFilaAmbientesOcupados: [] as ControleFilaAmbiente[],
	ControleFilaResponseListagemClientes: {} as ControleFilaListagemClientes,
	ControleFilaAdicionarClienteReponse: {} as ControleFilaAdicionarClienteReponse,
	controleFilaMessageModalOpen: false,
	controleFilaModalMessagem: '',

	controleFilaChangeTableModalOpen: false,
	controleFilaTableModalInfo: {} as ControleFilaMesa,

};

const controleFilaSlice = createSlice({
	name: 'controleFila',
	initialState,
	reducers: {
		changeControleFilaTableModalInfo: (state, action) => {
			state.controleFilaTableModalInfo = action.payload;
		},
		changeControleFilaOpenClientDrawer: (state, action) => {
			state.controleFilaOpenClientDrawer = action.payload;
		},
		changeControleFilaChangeTableModalOpen: (state, action) => {
			state.controleFilaChangeTableModalOpen = action.payload;
		},
		changeControleFilaPageType: (state, action) => {
			state.controleFilaPageType = action.payload;
		},
		changeControleFilaClientPreview: (state, action) => {
			state.controleFilaClientPreview = action.payload;
		},
		changeControleFilaAwaitingClients: (state, action) => {
			state.controleFilaAwaitingClients = action.payload;
		},
		changeControleFilaSelectedClient: (state, action) => {
			state.controleFilaSelectedClient = action.payload;
		},
		changeControleFilaLoginData: (state, action) => {
			state.loginData = action.payload;
		},
		changeMesasAgrupadas: (state, action) => {
			state.mesasAgrupadas = action.payload;
		},
		changeSelectedAmbiente: (state, action) => {
			state.selectedAmbiente = action.payload;
		},
		changeCheckedMesaLivre: (state, action) => {
			state.checkedMesaLivre = action.payload;
		},
		changeCheckedMesaOcupado: (state, action) => {
			state.checkedMesaOcupado = action.payload;
		},
		changeControleFilaPage: (state, action) => {
			state.controleFilaPage = action.payload;
		},
		changeControleFilaAmbientes: (state, action) => {
			state.controleFilaAmbientes = action.payload;
		},
		changeControleFilaAmbientesOcupados: (state, action) => {
			state.controleFilaAmbientesOcupados = action.payload;
		},
		changeControleFilaResponseListagemClientes: (state, action) => {
			state.ControleFilaResponseListagemClientes = action.payload;
		},
		changeControleFilaAdicionarClienteReponse: (state, action) => {
			state.ControleFilaAdicionarClienteReponse = action.payload;
		},
		changeControleFilaMesagemModalOpen: (state, action) => {
			state.controleFilaMessageModalOpen = action.payload;
		},
		changeControleFilaModalMessagem: (state, action) => {
			state.controleFilaModalMessagem = action.payload;
		},

		reset: () => initialState,
	},
});

export const {
	reset,
	changeCheckedMesaLivre,
	changeControleFilaAwaitingClients,
	changeControleFilaPageType,
	changeControleFilaSelectedClient,
	changeMesasAgrupadas,
	changeSelectedAmbiente,
	changeControleFilaChangeTableModalOpen,
	changeControleFilaLoginData,
	changeControleFilaPage,
	changeControleFilaAmbientes,
	changeControleFilaOpenClientDrawer,
	changeControleFilaTableModalInfo,
	changeControleFilaClientPreview,
	changeControleFilaAmbientesOcupados,
	changeControleFilaResponseListagemClientes,
	changeControleFilaAdicionarClienteReponse,
	changeCheckedMesaOcupado,
	changeControleFilaModalMessagem,
	changeControleFilaMesagemModalOpen,

} = controleFilaSlice.actions;
export default controleFilaSlice.reducer;
