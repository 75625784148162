import React from 'react';

export default function PixIcon() {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5654 23.2339L16.7383 18.4166C16.5619 18.2406 16.3534 18.2086 16.2412 18.2086C16.1289 18.2086 15.9204 18.2406 15.744 18.4166L10.9009 23.2499C10.3557 23.7941 9.50571 24.6743 6.66719 24.6743L12.6169 30.5959C13.5189 31.495 14.7417 32 16.0167 32C17.2916 32 18.5144 31.495 19.4165 30.5959L25.3822 24.6583C23.9228 24.6583 22.704 24.3702 21.5654 23.2339ZM10.9009 8.75009L15.744 13.5834C15.8723 13.7114 16.0648 13.7914 16.2412 13.7914C16.4176 13.7914 16.61 13.7114 16.7383 13.5834L21.5333 8.7981C22.672 7.61379 23.9709 7.34171 25.4303 7.34171L19.4646 1.40415C18.5625 0.505027 17.3397 0 16.0648 0C14.7898 0 13.567 0.505027 12.665 1.40415L6.7153 7.32571C9.53779 7.32571 10.4038 8.25395 10.9009 8.75009Z"
        fill="currentColor"
      />
      <path
        d="M30.6422 12.5586L27.0339 8.94168H25.0132C24.1472 8.94168 23.2813 9.29377 22.6879 9.91793L17.8768 14.7192C17.4278 15.1673 16.8344 15.3914 16.2411 15.3914C15.6301 15.3828 15.0452 15.1425 14.6053 14.7192L9.7622 9.86992C9.1528 9.26176 8.31889 8.90967 7.43686 8.90967H5.07945L1.40701 12.5906C0.506056 13.4909 0 14.7112 0 15.9835C0 17.2559 0.506056 18.4762 1.40701 19.3764L5.07945 23.0574H7.4529C8.31889 23.0574 9.1528 22.7053 9.77824 22.0972L14.6214 17.2639C15.0704 16.8158 15.6638 16.5917 16.2571 16.5917C16.8505 16.5917 17.4438 16.8158 17.8929 17.2639L22.72 22.0812C23.3294 22.6893 24.1633 23.0414 25.0453 23.0414H27.066L30.6742 19.4244C31.579 18.508 32.0835 17.2714 32.0775 15.9849C32.0714 14.6984 31.5554 13.4666 30.6422 12.5586Z"
        fill="currentColor"
      />
    </svg>
  );
}
