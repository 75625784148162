import React from 'react';

export default function AddIcon() {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0C4.11787 0 4.23092 0.0468253 4.31427 0.130175C4.39762 0.213524 4.44444 0.32657 4.44444 0.444444V4C4.44444 4.11787 4.39762 4.23092 4.31427 4.31427C4.23092 4.39762 4.11787 4.44444 4 4.44444H0.444444C0.32657 4.44444 0.213524 4.39762 0.130175 4.31427C0.0468253 4.23092 0 4.11787 0 4C0 3.88213 0.0468253 3.76908 0.130175 3.68573C0.213524 3.60238 0.32657 3.55556 0.444444 3.55556H3.55556V0.444444C3.55556 0.32657 3.60238 0.213524 3.68573 0.130175C3.76908 0.0468253 3.88213 0 4 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.55554 3.99999C3.55554 3.88211 3.60237 3.76907 3.68572 3.68572C3.76907 3.60237 3.88211 3.55554 3.99999 3.55554H7.55554C7.67342 3.55554 7.78646 3.60237 7.86981 3.68572C7.95316 3.76907 7.99999 3.88211 7.99999 3.99999C7.99999 4.11786 7.95316 4.23091 7.86981 4.31426C7.78646 4.39761 7.67342 4.44443 7.55554 4.44443H4.44443V7.55554C4.44443 7.67342 4.39761 7.78646 4.31426 7.86981C4.23091 7.95316 4.11786 7.99999 3.99999 7.99999C3.88211 7.99999 3.76907 7.95316 3.68572 7.86981C3.60237 7.78646 3.55554 7.67342 3.55554 7.55554V3.99999Z"
        fill="currentColor"
      />
    </svg>
  );
}
