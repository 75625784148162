import { createSlice } from '@reduxjs/toolkit';
import { DoLoginFidelity } from '@/services/fidelity/auth';
import UserStorage from '@/storages/user-storage';
import router from 'next/router';
import { RegisterFidelityResponse } from '@/models/registerFidelity.response';
import { DoRegisterFidelity } from '@/services/fidelity/register';

const initialState = {
  loading: false,
  loadingForm: false,
  responseLogin: <RegisterFidelityResponse | null>null,
  requestError: <null | string>null,
};

const registerFidelitySlice = createSlice({
  name: 'registerFidelity',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
    .addCase(DoRegisterFidelity.pending, (state) => {
      state.requestError = null;
      state.responseLogin = null;
      state.loadingForm = true;
    })
    .addCase(DoRegisterFidelity.fulfilled, (state, { payload }) => {
      state.loadingForm = true;
      if (payload) {
        if (payload.retorno) {
          UserStorage.SetFidelityLogin(payload.usuario_id);
          router.push('/fidelidade/pontuacao');
        } else {
          state.requestError = payload.mensagem;
          state.loadingForm = false;
        }
      }
    });
  },
});

export const { reset } = registerFidelitySlice.actions;
export default registerFidelitySlice.reducer;
