import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import UserStorage from '@/storages/user-storage';
import { Modal } from '@mui/material';
import { GetStatusTransition } from '@/services/payment';
import ErrorComp from './components/Error';
import ContentComp from './components/Content';
import { useMain } from '@/contexts/main';
import { usePages } from '@/contexts/pages';
import ModalSuccess from '../ModalSucess';
import { reset } from '@/redux/reducers/payment';

export default function ModalPix() {
	const { responsePayment, status } = useAppSelector(state => state.payment);
	const { loginFields, client } = useAppSelector(state => state.auth);
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const [openSuccess, setOpenSuccess] = useState(false);
	const countDownDate = new Date().getTime() + 5 * 60 * 1000;
	const [time, setTime] = useState(' ');
	const [requestInterval, setRequestInterval] = useState<any>(null);
	const [intervalTime, setIntervalTime] = useState<any>(null);
	const { qrcode } = useMain();
	const { setOpenPayment, setOpenSuccessPage, setOpenMyItems } = usePages();
	const [pixKey, setPixKey] = useState('');

	function clearAll() {
		UserStorage.RemovePix();
		UserStorage.RemoveTimePayment();
		clearInterval(requestInterval);
		clearInterval(intervalTime);
		setTime('5:00');
		setOpen(false);
	}

	function generateTime(downTime?: number) {
		const time = setInterval(function () {
			var now = new Date().getTime();
			var distance = (downTime || countDownDate) - now;

			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);
			UserStorage.SetTimePayment(minutes + '.' + seconds);

			if (distance < 0) {
				UserStorage.RemovePix();
				UserStorage.RemoveTimePayment();
				clearInterval(time);
				clearInterval(intervalTime);
				setTime('0:00');
			} else {
				setTime(minutes + ':' + (seconds < 10 ? '0' : '') + seconds);
			}
		}, 1000);
		setIntervalTime(time);
	}

	useEffect(() => {
		if (qrcode) {
			const storageTimePayment = UserStorage.GetTimePayment(qrcode);

			if (storageTimePayment) {
				const storagePix = UserStorage.GetPix(qrcode);
				if (storagePix) {
					setPixKey(storagePix.pixKey);
					setOpen(true);
					generateTime(new Date().getTime() + storageTimePayment * 60 * 1000);
					getStatus(storagePix.transitionId);
				}
			}
		}
	}, [qrcode]);

	async function getStatus(transitionId: number) {
		if (responsePayment) {
			UserStorage.SetPix({
				pixKey: responsePayment.chave_pix || '',
				transitionId: responsePayment.transacao,
			});
		}
		const interval = setInterval(async function () {
			await dispatch(GetStatusTransition(transitionId));
		}, 3000);
		setRequestInterval(interval);
	}

	useEffect(() => {
		if (status) {
			if (status.status !== '1' || time === '0:00') {
				UserStorage.RemovePix();
				UserStorage.RemoveTimePayment();
				return () => {
					clearInterval(requestInterval);
					clearInterval(intervalTime);
				};
			}
		}
	}, [status, time]);

	useEffect(() => {
		if (status) {
			if (status.status === '3') {
				if (client?.config_cliente?.comanda_sem_qrcode && client?.config_cliente?.usa_ticket) {
					setOpenSuccess(true);
					clearAll();
					setOpen(false);
					clearInterval(requestInterval);
					clearInterval(intervalTime);
				}
				if (loginFields || (client?.config_cliente?.comanda_sem_qrcode && !client?.config_cliente?.usa_ticket)) {
					if (loginFields?.config_qrcode.pre_pago || (client?.config_cliente?.comanda_sem_qrcode && !client?.config_cliente?.usa_ticket)) {
						setOpenSuccess(true);
						
					} else {
						setOpenSuccessPage(true);
					}
				}

				clearAll();
				setOpen(false);
				return () => {
					clearInterval(requestInterval);
					clearInterval(intervalTime);
				};
			}
		}
	}, [status]);

	useEffect(() => {
		if (responsePayment && responsePayment.chave_pix) {
			setPixKey(responsePayment.chave_pix);
			setOpen(true);
			generateTime();
			getStatus(responsePayment.transacao);
		}
	}, [responsePayment]);

	function onConfirmError() {
		dispatch(reset());
		clearAll();
		setOpenPayment(true);
	}

	function renderContent() {
		if ((status && status.status === '6') || time === '0:00') {
			return <ErrorComp onConfirmError={onConfirmError} />;
		} else {
			return <ContentComp time={time} pixKey={pixKey} />;
		}
	}

	return (
		<>
			<Modal open={open} id="modalPix" disablePortal disableEnforceFocus disableAutoFocus disableScrollLock>
				<div className="pixContent">{renderContent()}</div>
			</Modal>
			<ModalSuccess open={openSuccess} setOpen={setOpenSuccess} />
		</>
	);
}
